//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { vatRestrictedCountries } from '@/constants/countries'
import {
  get as _get,
  has as _has,
  cloneDeep as _cloneDeep,
  throttle as _throttle,
  capitalize as _capitalize,
} from 'lodash-es'
import { nanoid } from 'nanoid/non-secure'
import useModal from '@/composables/modal'
import { useLoading } from '@/composables/loading'
import { countriesList, commonCountries } from '@/constants/countries'
import {
  CONTACT_TYPES,
  CLIENT_TYPES,
  BUSINESS_TYPES,
  CONTACT_TYPE_CLIENT,
  CONTACT_TYPE_LEAD,
  CONTACT_LEGAL_STATUS_INDIVIDUAL,
  CONTACT_LEGAL_STATUS_COMPANY,
} from '@/constants/contacts'
import { defineComponent, useContext, computed, ref, reactive, watch } from '@nuxtjs/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useGetters } = createNamespacedHelpers('documents')

import { handleBackendFormError, hasFormErrors } from '@/utils/error-handler'
import { useUser } from '@/composables/user'
import { getAddressString, getPhoneNumber, isEmptyAddress, cleanAddresses } from '@/utils/normalizers'
import { watchDebounced } from '@vueuse/core'

const defaultForm = (params) => ({
  legalStatus: _get(params, 'legalStatus', CONTACT_LEGAL_STATUS_COMPANY),
  type: _get(params, 'type', CONTACT_TYPE_LEAD),
  civility: null,
  firstName: null,
  lastName: null,
  companyName: null,
  //forceNumber: false,
  //number: null,
  vatId: null,
  taxId: null,
  contacts: [],
  addresses: [
    {
      address: {
        street: null,
        complement: null,
        postal_code: null,
        city: null,
        country: 'fr',
      },
      primary: true,
      name: null,
      key: nanoid(),
    },
  ],
  emails: [
    {
      email: null,
      primary: true,
    },
  ],
  phones: [
    {
      phone: null,
      primary: true,
    },
  ],
})

const defaultErrors = () => ({})

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    contact: {
      type: Object,
      default: () => defaultForm(),
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: CONTACT_TYPE_LEAD,
    },
    allowTypes: {
      type: Array,
      default: () => CONTACT_TYPES,
    },
    data: {
      type: Object,
    },
    open: Boolean,
  },

  setup(props, { emit }) {
    const modal = useModal()
    const mapsId = ref(nanoid())
    const lastNameKey = ref(nanoid())

    const currentTab = ref('details')

    const { isLoading, setLoading } = useLoading()
    const { $axios, $contactsRepository, $bus, $toast, app } = useContext()
    const { company } = useUser()

    const legalStatuses = ref({
      [CONTACT_LEGAL_STATUS_INDIVIDUAL]: app.i18n.t(`contacts.label.legalStatus.${CONTACT_LEGAL_STATUS_INDIVIDUAL}`),
      [CONTACT_LEGAL_STATUS_COMPANY]: app.i18n.t(`contacts.label.legalStatus.${CONTACT_LEGAL_STATUS_COMPANY}`),
    })

    const zIndex = ref(modal.getZIndex())

    const allowContactType = computed(() => {
      if (props.mode === 'create') {
        return props.allowTypes
      } else {
        if (CLIENT_TYPES.includes(_get(props.contact, 'type', null))) {
          return CLIENT_TYPES
        } else if (BUSINESS_TYPES.includes(_get(props.contact, 'type', null))) {
          return BUSINESS_TYPES
        }
        return CONTACT_TYPES
      }
    })

    const selectCountries = computed(() => [
      ...Object.keys(commonCountries).reduce(
        (arr, key) => [
          ...arr,
          ...[
            {
              value: key,
              label: commonCountries[key],
            },
          ],
        ],
        []
      ),
      { value: null, label: '------', disabled: true },
      ...Object.keys(countriesList).reduce((arr, key) => {
        if (!Object.keys(commonCountries).includes(key)) {
          arr.push({ value: key, label: countriesList[key] })
        }
        return arr
      }, []),
    ])

    const addCollectionField = (field) => {
      switch (field) {
        case 'addresses':
          form.value.addresses.push({
            address: {
              street: '',
              complement: '',
              postal_code: '',
              city: '',
              country: company.value.country,
            },
            primary: false,
            name: null,
            key: nanoid(),
          })
          break
        case 'emails':
          form.value.emails.push({ email: '', primary: false, key: nanoid() })
          break
        case 'phones':
          form.value.phones.push({ phone: '', primary: false, key: nanoid() })
          break
        case 'contacts':
          form.value.contacts.push({
            civility: null,
            lastName: '',
            firstName: '',
            email: null,
            phone: null,
            primary: false,
            key: nanoid(),
          })
          break
      }

      if (!form.value[field].filter((el) => el.primary === true).length) {
        if (form.value[field][0]) {
          form.value[field][0].primary = true
        }
      }
    }

    const removeCollectionField = (method, index) => {
      form.value[method].splice(index, 1)
      if (!form.value[method].filter((el) => el.primary === true).length) {
        if (form.value[method][0]) {
          form.value[method][0].primary = true
        }
      }
    }

    const setPrimaryCollectionField = (method, primaryIndex) => {
      form.value[method] = form.value[method].map((el, index) => ({
        ...el,
        ...{ primary: index === primaryIndex },
      }))
    }

    const form = ref(defaultForm({ type: props.type }))
    const errors = reactive(defaultErrors())
    const backendErrors = ref({})

    const contactId = ref(null)
    const addressField = ref([])
    const invoiceDefaultPaymentDelay = ref(null)
    const invoiceDefaultDaysUntilDue = ref(null)

    const handleContactType = (type) => {
      form.value.type = type
    }

    const fillEmptyContactFields = (data) => {
      const contact = {}

      for (const key of Object.keys(defaultForm())) {
        if (_has(data, key)) {
          contact[key] = data[key]
        }
      }

      if (!contact.emails.length) {
        contact.emails = [{ email: '', key: nanoid(), primary: true }]
      }
      if (!contact.phones.length) {
        contact.phones = [{ phone: '', key: nanoid(), primary: true }]
      }
      if (!contact.contacts.length) {
        contact.contacts = [
          {
            name: null,
            civility: null,
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            primary: true,
            key: nanoid(),
          },
        ]
      }
      if (!contact.addresses.length) {
        contact.addresses = [
          {
            address: {
              street: '',
              complement: '',
              postal_code: '',
              city: '',
              country: company.value.country,
            },
            primary: true,
            name: null,
            key: nanoid(),
          },
        ]
      }

      return contact
    }

    watch(invoiceDefaultPaymentDelay, () => {
      invoiceDefaultDaysUntilDue.value = 30
    })

    watch(
      () => form.legalStatus,
      (val) => {
        lastNameKey.value = nanoid()
      }
    )

    watch(
      () => modal.isModalOpen(),
      (val) => {
        zIndex.value = modal.getZIndex()
        if (val === true) {
          mapsId.value = nanoid()
          form.value.type = props.type
          contactId.value = null

          form.value.legalStatus = [CONTACT_TYPE_CLIENT, CONTACT_TYPE_LEAD].includes(form.value.type)
            ? CONTACT_LEGAL_STATUS_INDIVIDUAL
            : CONTACT_LEGAL_STATUS_COMPANY

          if (props.mode === 'update' && _has(props.contact, 'id')) {
            contactId.value = props.contact.id

            const { updatedAt, createdAt, id, ...contact } = _cloneDeep(props.contact)

            invoiceDefaultPaymentDelay.value = contact.invoiceDefaultPaymentDelay
            invoiceDefaultDaysUntilDue.value = contact.invoiceDefaultDaysUntilDue

            if (contact.addresses.length === 0) {
              contact.addresses = [
                {
                  address: {
                    street: '',
                    complement: '',
                    postal_code: '',
                    city: '',
                    country: company.value.country,
                  },
                  primary: true,
                  name: null,
                  key: nanoid(),
                },
              ]
            } else {
              contact.addresses = contact.addresses.map((address) => {
                address.key = nanoid()
                return address
              })
            }

            contact.emails = contact.emails.map((email) => {
              email.key = nanoid()
              return email
            })

            contact.contacts = contact.contacts.map((contact) => {
              contact.key = nanoid()
              return contact
            })

            form.value = { ...fillEmptyContactFields(contact) }
          }
          if (props.name !== null) {
            const [lastName, ...firstName] = props.name.split(' ')
            form.value.lastName = lastName
            form.value.firstName = firstName.join(' ')
          }

          if (props.mode === 'create' && ![null, undefined].includes(props.data)) {
            for (const [k, v] of Object.entries(props.data)) {
              if (_has(form.value, k)) {
                form.value[k] = v
              }
            }
          }
        } else {
          if (props.mode === 'update' && _has(props.contact, 'id')) {
            resetForm()
          }
        }
      }
    )

    const submitFormRaw = () => {
      addressField.value.forEach((el, index) => {
        if (typeof el.isDirty === 'function' && el.isDirty()) {
          errors[`address_${index}`] = true
        }
      })
    }

    async function submitForm() {
      submitFormRaw()

      if (!hasFormErrors(errors)) {
        backendErrors.value = {}
        setLoading(true, 'create_contact')

        try {
          const payload = JSON.parse(JSON.stringify({ ...form.value }), (k, v) => {
            if (k === 'emails') {
              v = v.filter((el) => el.email)
            }
            if (k === 'phones') {
              v = v.filter((el) => el.phone)
            }
            if (k === 'contacts') {
              v = v.filter((el) => el.lastName || el.email || el.phone)
            }
            if (k === 'key') {
              v = undefined
            }
            return v
          })

          payload.addresses = cleanAddresses(payload.addresses)

          payload.invoiceDefaultPaymentDelay = invoiceDefaultPaymentDelay.value
          payload.invoiceDefaultDaysUntilDue =
            invoiceDefaultPaymentDelay.value === 'custom' ? invoiceDefaultDaysUntilDue.value : null

          if (payload.legalStatus === CONTACT_LEGAL_STATUS_COMPANY) {
            payload.firstName = null
            payload.lastName = null
          }

          const { data } = contactId.value
            ? await $contactsRepository.update(contactId.value, payload)
            : await $contactsRepository.create(payload)

          $toast.show(app.i18n.t(`contacts.notice.${props.mode}d`))
          $bus.emit(`${props.mode}.item`, { type: 'contact', data })
          emit(props.mode === 'create' ? 'created' : 'updated', data)
          if (props.open) {
            $bus.emit('openContact', data)
          }
          triggerCloseModal(false)
        } catch (err) {
          backendErrors.value = handleBackendFormError(app, err, 'contacts.field.error')
        } finally {
          setLoading(false, 'create_contact')
        }
      }
    }

    const resetForm = () => {
      form.value = { ...defaultForm({ type: props.type }) }
      contactId.value = null
    }

    function triggerCloseModal(abort = true) {
      if (abort) {
        emit('aborted')
      }
      currentTab.value = 'details'
      resetForm()
      modal.closeModal()
    }

    const handleFormChange = ($event) => {
      lastNameKey.value = nanoid()
    }

    const companyDefaultPaymentConditions = computed(() => {
      if ([null, 'custom'].includes(company.value.invoiceDefaultPaymentDelay)) {
        return `${company.value.invoiceDefaultDaysUntilDue} jours`
      }
      return app.i18n.t(`documents.builder.label.expireAt.${company.value.invoiceDefaultPaymentDelay}`)
    })

    const companyNameInput = ref()
    const suggestCompanies = ref([])
    const showCompanies = ref(false)

    const handleCompanyName = _throttle(async () => {
      if (['bf', 'fr'].includes(company.value.country) && form.value.companyName) {
        let container = _get(companyNameInput.value, '$el')
        if (container && _get(window, 'document.activeElement', null) === container.querySelector('input')) {
          try {
            setLoading(true, 'search_company')
            const { data } = await $axios.get('https://suggestions.pappers.fr/v2', {
              params: {
                cibles: 'nom_entreprise,denomination,nom_complet,siren,siret',
                q: form.value.companyName,
              },
              withCredentials: false,
              progress: false,
              errorMessage: false,
            })

            suggestCompanies.value = Object.values(data).reduce((arr, foundCompanies) => {
              foundCompanies.forEach((foundCompany) => {
                if (
                  arr.findIndex((el) => el.siren === foundCompany.siren) === -1 &&
                  _get(foundCompany, 'diffusable', true) !== false
                ) {
                  arr.push(foundCompany)
                }
              })
              return arr
            }, [])

            showCompanies.value = true
          } catch (err) {
          } finally {
            setLoading(false, 'search_company')
          }
        }
      }
    }, 500)

    const selectCompany = (data) => {
      showCompanies.value = false
      form.value.companyName = data.nom_entreprise || form.value.companyName

      if (_get(data, 'siege')) {
        let street = _get(data, 'siege.adresse_ligne_1', null)
        if (typeof street === 'string') street = _capitalize(street)

        let complement = _get(data, 'siege.adresse_ligne_2', null)
        if (typeof complement === 'string') complement = _capitalize(complement)

        let city = _get(data, 'siege.ville', null)
        if (typeof city === 'string') city = _capitalize(city)

        let countryName = _get(data, 'siege.pays', 'France')
        let country = Object.keys(countriesList).find((key) => _get(countriesList, key, null) === countryName)

        if (!country) country = 'fr'

        let address = {
          address: {
            street,
            complement,
            postal_code: _get(data, 'siege.code_postal', null),
            city,
            country,
          },
          primary: true,
          name: null,
          key: nanoid(),
        }

        form.value.addresses.splice(0, 1, address)

        form.value.taxId = _get(data, 'siege.siret', null)
      }
    }

    return {
      CONTACT_LEGAL_STATUS_COMPANY,
      CONTACT_LEGAL_STATUS_INDIVIDUAL,
      CONTACT_TYPE_CLIENT,
      CONTACT_TYPE_LEAD,
      props,
      lastNameKey,
      mapsId,
      ...modal,
      company,
      form,
      backendErrors,
      isLoading,
      addressField,
      selectCountries,
      legalStatuses,
      allowContactType,
      companyDefaultPaymentConditions,
      invoiceDefaultPaymentDelay,
      invoiceDefaultDaysUntilDue,
      suggestCompanies,
      showCompanies,
      handleCompanyName,
      companyNameInput,
      vatRestrictedCountries,
      selectCompany,
      handleFormChange,
      handleContactType,
      addCollectionField,
      removeCollectionField,
      setPrimaryCollectionField,
      submitForm,
      submitFormRaw,
      triggerCloseModal,
      currentTab,
      zIndex,
      _get,
    }
  },
})
