var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-30 relative"},[_c('div',{staticClass:"overflow-x-scroll md:overflow-x-visible"},[_c('div',{staticClass:"relative w-full h-full",class:[_vm.innerClass, _vm.hasBorder ? 'border-b border-gray-200' : '']},[_c('div',{staticClass:"w-full transform"},[_c('div',[_c('nav',{staticClass:"flex md:flex-wrap",class:_vm.spacingClass},[_vm._l((_vm.tabs),function(tab,index){
var _obj;
return _c('button',{key:("tab_" + tab + "_" + index),staticClass:"flex items-center py-2 whitespace-nowrap border-b-2 font-medium text-sm leading-5 focus:outline-none",class:( _obj = {
                'border-brand-400 text-brand-500 focus:text-brand-700':
                  index === _vm.tabs.findIndex(function (el) { return (el.tab || el) === _vm.selected; }),
                'border-transparent text-gray-500': index !== _vm.tabs.findIndex(function (el) { return (el.tab || el) === _vm.selected; }),
                'hover:text-gray-700 hover:border-gray-300':
                  !tab.disabled && index !== _vm.tabs.findIndex(function (el) { return (el.tab || el) === _vm.selected; }),
                'cursor-default': tab.disabled
              }, _obj[_vm.activeBorderClass] = index === _vm.tabs.findIndex(function (el) { return (el.tab || el) === _vm.selected; }), _obj['mr-6'] =  index !== _vm.tabs.length - 1, _obj[_vm.itemClass] = true, _obj ),attrs:{"type":"button"},on:{"click":function($event){!_vm._has(tab, 'can') || tab.can
                  ? tab.disabled
                    ? _vm.$emit('clickedDisabled', tab)
                    : _vm.selectTab(tab)
                  : _vm.$bus.emit('openPlans')}}},[_vm._t("item",function(){return [(tab.activeIcon && index === _vm.tabs.findIndex(function (el) { return (el.tab || el) === _vm.selected; }))?_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":tab.activeIcon}}):(tab.icon)?_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":tab.icon}}):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t((_vm.trans + "." + (tab.tab || tab))))}})]},{"tab":tab,"active":index === _vm.tabs.findIndex(function (el) { return (el.tab || el) === _vm.selected; })})],2)}),_vm._v(" "),_vm._t("default")],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }