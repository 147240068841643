//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import { defineComponent, ref, computed, watch, nextTick, provide } from '@nuxtjs/composition-api'
import Teleport from 'vue2-teleport'

export default defineComponent({
  components: { Teleport },
  props: {
    isOpen: Boolean,
    autofocus: Boolean,
    transparent: Boolean,
    hasScroll: {
      type: Boolean,
      default: true,
    },
    innerScrollbar: {
      type: Boolean,
      default: true,
    },
    fullScreen: Boolean,
    maxScrollHeight: {
      type: String,
      default: '100vh',
    },
    overlayClass: {
      type: String,
      default: 'bg-gray-400  opacity-50',
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    contentClasses: {
      type: String,
      default: '',
    },
    centered: {
      type: Boolean,
    },
    zIndexNumber: {
      type: Number,
      default: null,
    },
    zIndex: {
      type: String,
      default: '!z-[10001]',
    },
  },
  setup(props) {
    const modalContent = ref(null)

    const options = computed(() => ({
      wheelPropagation: true,
      suppressScrollX: true,
      suppressScrollY: !props.innerScrollbar,
    }))

    watch(
      () => props.isOpen,
      async (val) => {
        await nextTick()
        if (val === true && modalContent.value !== null && props.autofocus) {
          let firstInput = modalContent.value.querySelector(
            '.autofocus-inside input, .autofocus-inside div[contenteditable="true"], .autofocus-inside textarea'
          )

          if (firstInput && typeof _get(firstInput, 'focus', null) === 'function') {
            firstInput.focus()
          }
        }
      }
    )

    return { props, modalContent, options }
  },
})
