import { has as _has, get as _get } from 'lodash-es'

import * as constants from '@/constants/companies'
import { availableCurrencies } from '@/config/locales'

export const rgbToHex = ([r, g, b]) => {
  r = parseInt(r).toString(16)
  g = parseInt(g).toString(16)
  b = parseInt(b).toString(16)

  if (r.length == 1) r = '0' + r
  if (g.length == 1) g = '0' + g
  if (b.length == 1) b = '0' + b

  return '#' + r + g + b
}

export const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const isLargeImage = function (file) {
  if (_has(file, 'width') && _has(file, 'height')) {
    const ratio = file.width / file.height

    // Consider small images "large" so they are contained in the gallery
    if (ratio < 1.2 && file.width < 100) {
      return true
    }

    return ratio > 1.2
  }

  return false
}

export const insertAfter = function (newElement, targetElement) {
  let parent = targetElement.parentNode
  if (parent.lastChild === targetElement) {
    parent.appendChild(newElement)
  } else {
    parent.insertBefore(newElement, targetElement.nextSibling)
  }
}

export const getImmediatePreviousSiblings = function (element, filter) {
  const siblings = []
  while ((element = element.previousSibling) && filter(element)) {
    siblings.push(element)
  }
  return siblings
}

export const getNextSiblings = function (element, filter) {
  const siblings = []
  while ((element = element.nextSibling)) {
    if (!filter || filter(element)) {
      siblings.push(element)
    }
  }
  return siblings
}

export const getAbsoluteHeight = function (el) {
  // Get the DOM Node if you pass in a string
  el = typeof el === 'string' ? document.querySelector(el) : el

  const styles = window.getComputedStyle(el)
  const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])

  return Math.ceil(el.offsetHeight + margin)
}

// Parses the DocumentPreviewBreakableDom component contents and rebuilds a document on separate PDF-like pages
export const makeDocumentPages = (clear = false, event = null, fragment = null) =>
  new Promise((resolve, reject) => {
    if (fragment !== null) {
      const t = performance.now()

      let doc = fragment

      if (clear) {
        const pages = [...document.getElementsByClassName('previewContainer')]
        const firstPage = pages.shift()

        if (firstPage) {
          // Empty first page table
          const table = firstPage.getElementsByClassName('document-lines')[0]
          ;[...table.children].forEach((child) => {
            if (!child.classList.contains('main-head')) {
              child.remove()
            }
          })

          // Removes copied elements
          const copies = firstPage.querySelectorAll('.copy:not(.document-lines)')
          ;[...copies].forEach((copy) => copy.remove())

          const tableBoundary = document.createElement('tbody')
          tableBoundary.className = 'renderDocumentTable'
          table.appendChild(tableBoundary)

          const contentBoundary = document.createElement('br')
          contentBoundary.className = 'renderDocumentContent h-0 hidden'
          table.insertAdjacentElement('afterend', contentBoundary)

          pages.forEach((page) => page.remove())
        }
      }

      for (const el of doc.children) {
        const element = el.cloneNode(true)

        // "table data" and "after content" has their own boundaries
        const boundaryType = ['DIV'].includes(element.tagName) ? 'content' : 'table'

        // Inserts every group + child products
        /*if (element.classList.contains('table-group-container')) {
          // Group header + spacer
          const headChildren = element.getElementsByClassName('group-head-element')
          for (const child of headChildren) {
            const headElement = child.cloneNode(true)
            insertOrCreatePage(headElement, boundaryType)
          }*/
        // Group content
        /*const children = element.getElementsByClassName('group-children')[0].children

          for (const groupChild of children) {
            const childElement = groupChild.cloneNode(true)
            insertOrCreatePage(childElement, boundaryType)
          }*/
        //} else {
        // Inserts every product row
        if (element.tagName === 'TABLE') {
          const children = element.querySelectorAll('tr')
          for (const tableChild of children) {
            insertOrCreatePage(tableChild, boundaryType)
          }
        } else {
          // Inserts every other element not in table
          insertOrCreatePage(element, boundaryType)
        }
        //}
      }

      const t1 = performance.now()

      // TODO pull down totals block
      /*const previewBox = window.document.getElementsByClassName('previewBox')[0]
      if (previewBox) {
        const bottomDom = previewBox.getElementsByClassName('page-bottom')[0]
        const footerHeight = previewBox.getAttribute('footerHeight')

        if (bottomDom) {
          bottomDom.classList.add('mt-auto', 'relative')
          bottomDom.style.top = `-${footerHeight - 20}px`
        }
      }*/

      //console.log(`[preview] created pages in ${Math.round(t1 - t)}ms`)
      resolve()
    } else {
      reject()
    }
  })

const cloneCanvas = (oldCanvas) => {
  // create a new canvas
  let newCanvas = document.createElement('canvas')
  let context = newCanvas.getContext('2d')

  newCanvas.width = 80
  newCanvas.height = 80

  // apply the old canvas to the new one
  // forces 80px width for the new canvas (oldCanvas.width is 128 on mobile devices)
  context.drawImage(oldCanvas, 0, 0, 80, 80)

  return newCanvas
}

const insertOrCreatePage = (element, boundaryType) => {
  element.classList.add('copy')

  // Table data and after content has their own boundaries
  const boundaries = {
    table: window.document.getElementsByClassName('renderDocumentTable')[0],
    // Gets the last renderDocumentContent boundary to avoid element placed before last table
    // (e.g. totals table was before the end of product tables when having multiple pages)
    content: Array.from(window.document.getElementsByClassName('renderDocumentContent')).pop(),
  }

  const boundary = boundaries[boundaryType]

  // Gets the page containing the current boundary
  // Assuming the current boundary is always the lowest on page, we can't go back from "content" to "table" because the DOM parsing is sequential
  const currentPage = boundary.closest('.previewContainer')
  const currentFooter = currentPage.querySelector('.document-footer')
  let footerHeight = 0

  if (null !== currentFooter) {
    footerHeight = getAbsoluteHeight(currentFooter)
  }

  boundary.closest('.previewBox').setAttribute('footerHeight', footerHeight)

  boundary.insertAdjacentElement('beforebegin', element)

  // Removes the current element if it overflows current page and insert it on a new page
  if (
    element.classList.contains('page-break') ||
    element.getBoundingClientRect().bottom + footerHeight > currentPage.getBoundingClientRect().bottom
  ) {
    const tableClasses = document.querySelectorAll('.document-lines:not(.table-group-container)')[0].classList || ''
    const tableHead = document.getElementsByClassName('main-head')[0]

    const newPageElement = element.cloneNode(true)
    element.remove()

    const newPage = currentPage.cloneNode()

    //currentPage.querySelectorAll('.renderDocumentContent')[0].remove()

    const pageInner = document.createElement('div')
    pageInner.classList.add('previewDocument', 'flex', 'flex-col')

    // Creates a new table for inserting table rows
    if (boundaryType === 'table') {
      const newTable = document.createElement('table')
      newTable.className = tableClasses

      const newHead = tableHead.cloneNode(true)
      newTable.appendChild(newHead)

      /*const headSpacer = document.createElement('tr')
      headSpacer.innerHTML = '<tr><div class="h-1"></div>'
      newTable.appendChild(headSpacer)*/

      pageInner.appendChild(newTable)

      // Checks if last element of last page is part of a Group heading and moves the group to the next page
      {
        const lastPageTables = currentPage.querySelectorAll(`.${[...tableClasses].join('.')}`)
        const lastTable = lastPageTables[lastPageTables.length - 1]

        const children = [...lastTable.children].filter((el) => !el.classList.contains('renderDocumentTable'))
        const lastChild = children[children.length - 1]

        if (lastChild && lastChild.classList.contains('group-head-element')) {
          const previousHeadings = getImmediatePreviousSiblings(lastChild, (el) => {
            try {
              return el.classList.contains('group-head-element')
            } catch (err) {
              return false
            }
          }).reverse()

          const headingGroup = previousHeadings.concat(lastChild)

          for (const heading of headingGroup) {
            const newHeading = heading.cloneNode(true)
            heading.remove()
            newTable.appendChild(newHeading)
          }
        }
      }

      // Inserts copied element in the new table
      // Moves the table boundary inside new table
      newTable.appendChild(newPageElement)
      newTable.appendChild(boundaries.table)
    } else {
      // Inserts new element in the new page
      pageInner.appendChild(newPageElement)
    }
    // Moves content boundary in the new page
    pageInner.appendChild(boundaries.content)

    newPage.appendChild(pageInner)

    const newPageFooter = currentFooter.cloneNode(true)

    const editLink = newPageFooter.querySelector('.footerEditLink')
    if (editLink) {
      editLink.remove()
    }

    // Clones the qr-code canvas
    const qrCode = currentFooter.querySelector('canvas')
    if (qrCode !== null) {
      const newQrCode = newPageFooter.querySelector('canvas')
      const newCanvas = cloneCanvas(qrCode)
      newQrCode.replaceWith(newCanvas)
    }

    newPage.appendChild(newPageFooter)

    currentPage.insertAdjacentElement('afterend', newPage)

    newPage.style.visibility = 'visible'
    newPage.style.opacity = 1
  }
}

export const chunk_split = (body, chunklen, end) => {
  chunklen = parseInt(chunklen, 10) || 76
  end = end || '\r\n'
  if (chunklen < 1) {
    return ''
  }
  if (body === null) {
    return ''
  }
  return body.match(new RegExp('.{0,' + chunklen + '}', 'g')).join(end)
}

export const getDocumentFooter = (company, i18n, force = false) => {
  if (company.documentCustomFooter && !force) {
    return company.documentFooter || ''
  }

  const currencySymbol = _get(
    availableCurrencies,
    `${_get(company.value, 'currency', 'eur').toLowerCase()}.symbol`,
    '€'
  )

  let address = null

  let postalCode = _get(company, 'address.postal_code', '')
  let city = _get(company, 'address.city', '')

  if (company.address) {
    const zipAndCity = [postalCode, city].filter(Boolean).join(' ')

    address = [company.address.street, company.address.complement]
      .map((el) => (typeof el !== 'undefined' && el !== null ? el.toString().trim() : ''))
      .filter(Boolean)
      .join(' - ') // TODO country

    address += ` ${zipAndCity}`
  }

  let capital = null
  if (company.shareCapital > 0) {
    capital = `Capital de ${company.shareCapital} ${currencySymbol}`
  }

  let formatedTaxId = company.taxId || ''
  let taxName = 'SIREN'

  if (company.taxId) {
    if (formatedTaxId.length > 9) {
      taxName = 'SIRET'
      formatedTaxId =
        chunk_split('' + company.taxId.slice(0, 9).replaceAll(' ', '').trim(), 3, ' ') + company.taxId.slice(9)
    } else {
      formatedTaxId = chunk_split('' + company.taxId.replaceAll(' ', '').trim(), 3, ' ')
    }
  }

  let complement = ''
  if (company.taxCity) {
    switch (company.country) {
      case 'fr':
      case 'gp':
      case 're':
      case 'mq':
        complement = `RCS ${company.taxCity}${company.taxId ? ' ' + formatedTaxId : ''}`
        break
      case 'be':
        complement = `RPM ${company.taxCity}`
        break
    }
  }

  if (company.tradeDirectory && company.taxId) {
    complement = `${taxName} ${formatedTaxId} - RM ${company.tradeDirectory}`
  }

  if (!company.taxCity && !company.tradeDirectory && company.taxId) {
    complement = `${taxName} ${formatedTaxId}`
  }

  if (company.vatId) {
    if (complement) {
      complement += ' - '
    }
    complement += `TVA : ${company.vatId}`
  }

  let companyName = company.name

  if (i18n.te(`account.label.type.${company.country}.${company.type}_documentFooter`)) {
    companyName += ` ${i18n.t(`account.label.type.${company.country}.${company.type}_documentFooter`)}`
  } else if (i18n.te(`account.label.type.${company.country}.${company.type}`)) {
    companyName += ` ${i18n.t(`account.label.type.${company.country}.${company.type}`)}`
  } else if (i18n.te(`account.label.type.default.${company.type}`)) {
    companyName += ` ${i18n.t(`account.label.type.default.${company.type}`)}`
  }

  let companyAddress = [companyName, address]
    .map((el) => (typeof el !== 'undefined' && el !== null ? el.toString().trim() : ''))
    .filter(Boolean)
    .join(' - ')

  let type = [capital, complement]
    .map((el) => (typeof el !== 'undefined' && el !== null ? el.toString().trim() : ''))
    .filter(Boolean)
    .join(' - ')

  let insurance = null

  if (company.insuranceGuarantee) {
    let insuranceType = i18n.t(`account.label.footerInsuranceGuarantee.${company.insuranceGuarantee}`)

    const insuranceZipAndCity = company.insuranceAddress
      ? `${_get(company, 'insuranceAddress.postal_code', null) || ''} ${
          _get(company, 'insuranceAddress.city', null) || ''
        }`
      : ''

    const insuranceAddress =
      company.insuranceAddress === null
        ? null
        : [
            _get(company, 'insuranceAddress.street', null) || '',
            _get(company, 'insuranceAddress.complement', null) || '',
            insuranceZipAndCity,
          ] // TODO country
            .map((el) => (typeof el !== 'undefined' && el !== null ? el.toString().trim() : ''))
            .filter(Boolean)
            .join(' ')

    insurance = `${[
      (company.insuranceName ? `${insuranceType} : ${company.insuranceName}` : insuranceType) +
        (company.insuranceContractNumber ? ` n° ${company.insuranceContractNumber}` : ''),
      insuranceAddress ? `${insuranceAddress}` : '',
    ]
      .map((el) => (typeof el !== 'undefined' && el !== null ? el.toString().trim() : ''))
      .filter(Boolean)
      .join(' - ')}`

    if (![null, ''].includes(company.insuranceCoverage)) {
      insurance += ` (${company.insuranceCoverage.trim()})`
    }
  }

  let data = [companyAddress, type, insurance]
    .map((el) => (typeof el !== 'undefined' && el !== null ? el.toString().trim() : ''))
    .filter(Boolean)
    .join('<br />')

  return data || ''
}

export function getTaxName(company) {
  let formatedTaxId = company.taxId || ''
  let taxName = 'SIREN'

  if (company.taxId) {
    if (formatedTaxId.length > 9) {
      taxName = 'SIRET'
      formatedTaxId =
        chunk_split('' + company.taxId.slice(0, 9).replaceAll(' ', '').trim(), 3, ' ') + company.taxId.slice(9)
    } else {
      formatedTaxId = chunk_split('' + company.taxId.replaceAll(' ', '').trim(), 3, ' ')
    }
  }

  if (company.taxCity) {
    switch (company.country) {
      case 'fr':
      case 'gp':
      case 're':
      case 'mq':
        return `RCS ${company.taxCity}${company.taxId ? ' ' + formatedTaxId : ''}`
        break
      case 'be':
        return `RPM ${company.taxCity}`
        break
    }
  }

  if (company.tradeDirectory && company.taxId) {
    return `${taxName} : ${formatedTaxId} - RM ${company.tradeDirectory}`
  }

  if (!company.taxCity && !company.tradeDirectory && company.taxId) {
    return `${taxName} : ${formatedTaxId}`
  }
}

export const nl2br = (str) => {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2')
}
