import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=4deea1a2&"
import script from "./Notifications.vue?vue&type=script&lang=js&"
export * from "./Notifications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotificationsContainer: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/notifications/NotificationsContainer.vue').default})
