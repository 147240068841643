//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultTo as _defaultTo, get as _get, has as _has, throttle as _throttle } from 'lodash-es'
import { onClickOutside } from '@vueuse/core'

import { computed, defineComponent, nextTick, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api'
import { nanoid } from 'nanoid/non-secure'
import { useUser } from '@/composables/user'
import { useLoading } from '@/composables/loading'
import { getAddressString } from '@/utils/normalizers'

export default defineComponent({
  props: {
    value: {
      type: Object,
      default: false,
    },
    countries: Array,
    label: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { can } = useUser()
    const { isLoading } = useLoading()
    const mapsId = ref(nanoid())
    const address = ref({
      street: '',
      complement: '',
      postal_code: '',
      city: '',
      country: 'fr',
    })

    const token = ref(null)
    const autoCompleteSuggestion = ref(null)

    const results = ref([])
    const showResults = ref(false)
    const showFullAddress = ref(false)
    const showSuggestions = ref(true)

    const addressSearch = ref(null)
    const showAddressString = ref(false)

    const addressBlock = ref(null)

    onClickOutside(addressBlock, (event) => {
      showFullAddress.value = true
      showFullAddress.value = false
      showAddressString.value = hasAddressParts.value
    })

    const hideSuggestions = () => {
      showSuggestions.value = false
      if (addressSearch.value) {
        addressSearch.value.$el.getElementsByTagName('input')[0].focus()
      }
    }

    const handleFocus = () => {
      showFullAddress.value = true
    }

    const handleKeyup = ($event) => {
      // Reset address fields if street is emptied
      if ($event.target.value === '') {
        address.value.complement = null
        address.value.postal_code = null
        address.value.city = null
      }

      openSuggestions($event)
    }

    const openSuggestions = _throttle(async ($event) => {
      // Only open google suggestions if address is empty
      if (
        $event.target.value &&
        autoCompleteSuggestion.value &&
        !_get(address.value, 'complement', null) &&
        !_get(address.value, 'postal_code', null) &&
        !_get(address.value, 'city', null)
      ) {
        try {
          const { suggestions } = await autoCompleteSuggestion.value.fetchAutocompleteSuggestions({
            input: $event.target.value,
            language: 'fr-FR',
            region: 'fr',
            sessionToken: token.value,
          })

          let places = []
          for (const suggestion of suggestions) {
            places.push(suggestion.placePrediction)
          }

          results.value = places
          showResults.value = results.value.length > 0
        } catch (err) {}
      }
    }, 1500)

    const hideFullAddress = () => {
      if (
        !_get(address.value, 'complement', null) &&
        !_get(address.value, 'postal_code', null) &&
        !_get(address.value, 'city', null)
      ) {
        showFullAddress.value = false
      }
    }

    const hideResults = () => {
      showResults.value = false
    }

    const selectResult = async (result) => {
      try {
        let place = result.toPlace()

        await place.fetchFields({
          fields: ['displayName', 'types', 'addressComponents'],
        })

        const components = Object.values(place.addressComponents)

        let street = components.reduce((arr, component) => {
          if (component.types.findIndex((el) => ['street_number', 'route'].includes(el)) !== -1) {
            arr.push(component.longText)
          }
          return arr
        }, [])

        if (Array.isArray(street) && street.length === 0) {
          street = components.reduce((arr, component) => {
            if (component.types.findIndex((el) => ['neighborhood'].includes(el)) !== -1) {
              arr.push(component.longText)
            }
            return arr
          }, [])
        }

        if (Array.isArray(street) && street.length === 0) {
          street = place.types.filter((el) => ['point_of_interest', 'colloquial_area'].includes(el)).length
            ? place.displayName
            : null
        }

        street = street.join(' ')

        const postalCode = components.reduce((str, component) => {
          if (component.types.findIndex((el) => el === 'postal_code') !== -1) {
            str = component.longText
          }
          return str
        }, null)

        const city = components.reduce((str, component) => {
          if (component.types.findIndex((el) => ['locality', 'postal_town'].includes(el)) !== -1) {
            str = component.longText
          }
          return str
        }, null)

        const country = components.reduce((str, component) => {
          if (component.types.findIndex((el) => el === 'country') !== -1) {
            str = component.shortText
          }
          return str
        }, null)

        address.value.street = street
        address.value.postal_code = postalCode
        address.value.city = city
        address.value.country = _defaultTo(country, 'fr').toLowerCase()
        await nextTick()
        hideResults()
      } catch (err) {}
    }

    const selectValue = function ($event) {
      emit('input', $event)
    }

    const hasAddressParts = computed(() => {
      if (
        _get(address.value, 'complement', null) ||
        _get(address.value, 'postal_code', null) ||
        _get(address.value, 'city', null)
      ) {
        return true
      }
      return false
    })

    const hideAddressString = () => {
      showFullAddress.value = false
      showAddressString.value = false
      showFullAddress.value = true
      if (addressSearch.value) {
        addressSearch.value.$el.getElementsByTagName('input')[0].focus()
      }
    }

    watch(
      () => props.value,
      (val) => {
        address.value = val
      }
    )

    onMounted(async () => {
      if (google && google.maps && can('plan', 'products.address_autocomplete')) {
        const { AutocompleteSessionToken, AutocompleteSuggestion } = await google.maps.importLibrary('places')

        token.value = new AutocompleteSessionToken()
        autoCompleteSuggestion.value = AutocompleteSuggestion
      }

      if (props.value) {
        address.value = props.value
        if (hasAddressParts.value) {
          showAddressString.value = true
        }
      }
    })

    return {
      props,
      mapsId,
      results,
      showResults,
      address,
      addressSearch,
      addressBlock,
      showFullAddress,
      hasAddressParts,
      showAddressString,
      showSuggestions,
      can,
      hideSuggestions,
      hideAddressString,
      isLoading,
      hideResults,
      selectResult,
      handleFocus,
      handleKeyup,
      hideFullAddress,
      selectValue,
      getAddressString,
    }
  },
})
