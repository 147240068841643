//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { computed, defineComponent, onMounted, useContext, useRoute } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'
import confetti from 'canvas-confetti'
export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const modal = useModal()
    const { $bus } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const route = useRoute()

    const status = computed(() => {
      if (_get(route.value.query, 'payment_success', false)) {
        return 'success'
      }
      if (_get(route.value.query, 'payment_cancel', false)) {
        return 'cancel'
      }
      return null
    })

    const product = computed(() => _get(route.value.query, 'product', 'default'))

    onMounted(() => {
      if (status.value !== null) {
        modal.openModal()
        if (status.value === 'success') {
          const plan = _get(route.value.query, 'plan', null)
          const uniqId = _get(route.value.query, 'event_id', null)

          if (window.dataLayer) {
            window.dataLayer.push({ event: 'subscribe_paid', plan, uniqId })
            if (plan) window.dataLayer.push({ event: `subscribe_${plan}`, plan, uniqId })
          }

          setTimeout(() => {
            confetti({
              particles: 100,
              zIndex: 99999999,
            })
          }, 500)
        }
      }
    })

    const openChat = () => {
      $bus.emit('openChat')
      modal.closeModal()
    }

    return {
      props,
      ...modal,
      status,
      product,
      openChat,
      isLoading,
      hasErrorState,
    }
  },
})
