export const DOCUMENT_STATUS_ALL = 'all'
export const DOCUMENT_STATUS_DRAFT = 'draft'
export const DOCUMENT_STATUS_OPEN = 'open'
export const DOCUMENT_STATUS_PAID = 'paid'
export const DOCUMENT_STATUS_ACCEPTED = 'accepted'
export const DOCUMENT_STATUS_REFUSED = 'refused'
export const DOCUMENT_STATUS_CANCELLED = 'cancelled'
export const DOCUMENT_STATUS_DELETED = 'deleted'
export const DOCUMENT_STATUS_OVERDUE = 'overdue'

export const DOCUMENT_STATUS_TO_REVIEW = 'to_review'
export const DOCUMENT_STATUS_TO_PAY = 'to_pay'
export const DOCUMENT_STATUS_SCHEDULED = 'scheduled'
export const DOCUMENT_STATUS_SHIPPED = 'shipped'
export const DOCUMENT_STATUS_DELIVERED = 'delivered'

export const QUOTE_STATUSES = [
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_OPEN,
  DOCUMENT_STATUS_ACCEPTED,
  DOCUMENT_STATUS_REFUSED,
  DOCUMENT_STATUS_CANCELLED,
]

export const INVOICE_STATUSES = [
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_OPEN,
  DOCUMENT_STATUS_PAID,
  DOCUMENT_STATUS_CANCELLED,
]

export const SUPPLIER_INVOICE_STATUSES = [
  DOCUMENT_STATUS_TO_REVIEW,
  DOCUMENT_STATUS_TO_PAY,
  DOCUMENT_STATUS_SCHEDULED,
  DOCUMENT_STATUS_PAID,
]

export const ORDER_FORM_STATUSES = [
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_OPEN,
  DOCUMENT_STATUS_SHIPPED,
  DOCUMENT_STATUS_DELIVERED,
  DOCUMENT_STATUS_CANCELLED,
]

export const SUPPLIER_CREDIT_STATUSES = [DOCUMENT_STATUS_TO_REVIEW, DOCUMENT_STATUS_TO_PAY, DOCUMENT_STATUS_PAID]

export const CREDIT_STATUSES = [DOCUMENT_STATUS_DRAFT, DOCUMENT_STATUS_OPEN, DOCUMENT_STATUS_CANCELLED]

export const QUOTE = 'quote'
export const INVOICE = 'invoice'
export const SUPPLIER_INVOICE = 'supplier_invoice'
export const CREDIT = 'credit'
export const ORDER_FORM = 'order_form'

export const DOCUMENT_TYPES = [QUOTE, INVOICE]

export const DOCUMENT_OPTION_HIDE_WORK_DETAILS = 'hide_work_details'
export const DOCUMENT_OPTION_DISPLAY_WORK_PRICES = 'display_work_prices'
export const DOCUMENT_OPTION_DISPLAY_WORK_QTY = 'display_work_qty'
export const DOCUMENT_OPTION_DISPLAY_NUMBERING = 'display_numbering'
export const DOCUMENT_OPTION_DISPLAY_REFERENCE = 'display_reference'
export const DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE = 'display_company_signature'
export const DOCUMENT_OPTION_HIDE_REVISION_NUMBER = 'hide_revision_number'
export const DOCUMENT_OPTION_DISPLAY_PROJECT_NAME = 'display_project_name'
export const DOCUMENT_OPTION_DISPLAY_ASSIGNEE = 'display_assignee'
export const DOCUMENT_OPTION_DISPLAY_ASSIGNEE_PHONE = 'display_assignee_phone'
export const DOCUMENT_OPTION_DISPLAY_ASSIGNEE_EMAIL = 'display_assignee_email'
export const DOCUMENT_OPTION_MERGE_QUANTITY_UNITS = 'merge_quantity_units'
export const DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL = 'display_group_subtotal'
export const DOCUMENT_OPTION_DISPLAY_TAX_DETAILS = 'display_tax_details'
export const DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY = 'display_section_summary'
export const DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY = 'display_situation_summary'
export const DOCUMENT_OPTION_HIDE_SITUATION_UNIT = 'hide_situation_unit'
export const DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES = 'hide_situation_el'
export const DOCUMENT_OPTION_DISABLE_QRCODE = 'disable_qrcode'
export const DOCUMENT_OPTION_HIDE_PRICES = 'hide_prices'

export const DOCUMENT = 'document'
export const BUILDER = 'builder'

export const DOCUMENT_OPTIONS = {
  [DOCUMENT_OPTION_DISPLAY_NUMBERING]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_REFERENCE]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_TAX_DETAILS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_PROJECT_NAME]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_ASSIGNEE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_ASSIGNEE_PHONE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_ASSIGNEE_EMAIL]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_WORK_DETAILS]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_PRICES]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_QTY]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_REVISION_NUMBER]: [DOCUMENT],
  [DOCUMENT_OPTION_MERGE_QUANTITY_UNITS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISABLE_QRCODE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_HIDE_SITUATION_UNIT]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_PRICES]: [DOCUMENT],
}

export const INVOICE_OPTIONS = {
  [DOCUMENT_OPTION_DISPLAY_NUMBERING]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_REFERENCE]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_TAX_DETAILS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_PROJECT_NAME]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_WORK_DETAILS]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_PRICES]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_QTY]: [DOCUMENT],
  [DOCUMENT_OPTION_MERGE_QUANTITY_UNITS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISABLE_QRCODE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_HIDE_SITUATION_UNIT]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_PRICES]: [DOCUMENT],
}

export const CREDIT_OPTIONS = {
  [DOCUMENT_OPTION_DISPLAY_NUMBERING]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_REFERENCE]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_TAX_DETAILS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_PROJECT_NAME]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_WORK_DETAILS]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_PRICES]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_QTY]: [DOCUMENT],
  [DOCUMENT_OPTION_MERGE_QUANTITY_UNITS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISABLE_QRCODE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_HIDE_SITUATION_UNIT]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES]: [DOCUMENT],
}

export const QUOTE_OPTIONS = {
  [DOCUMENT_OPTION_DISPLAY_NUMBERING]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_REFERENCE]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_TAX_DETAILS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_PROJECT_NAME]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_WORK_DETAILS]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_PRICES]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_WORK_QTY]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_REVISION_NUMBER]: [DOCUMENT],
  [DOCUMENT_OPTION_MERGE_QUANTITY_UNITS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISABLE_QRCODE]: [DOCUMENT],
}

export const ORDER_FORM_OPTIONS = {
  [DOCUMENT_OPTION_DISPLAY_NUMBERING]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_REFERENCE]: [BUILDER, DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE]: [DOCUMENT],
  [DOCUMENT_OPTION_DISPLAY_PROJECT_NAME]: [DOCUMENT],
  [DOCUMENT_OPTION_MERGE_QUANTITY_UNITS]: [DOCUMENT],
  [DOCUMENT_OPTION_DISABLE_QRCODE]: [DOCUMENT],
  [DOCUMENT_OPTION_HIDE_PRICES]: [DOCUMENT],
}

export const DOCUMENT_OPTIONS_GROUPS = {
  lines: [
    DOCUMENT_OPTION_DISPLAY_NUMBERING,
    DOCUMENT_OPTION_DISPLAY_REFERENCE,
    DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL,
    DOCUMENT_OPTION_HIDE_WORK_DETAILS,
    DOCUMENT_OPTION_DISPLAY_WORK_PRICES,
    DOCUMENT_OPTION_DISPLAY_WORK_QTY,
    DOCUMENT_OPTION_MERGE_QUANTITY_UNITS,
    DOCUMENT_OPTION_HIDE_REVISION_NUMBER,
    DOCUMENT_OPTION_HIDE_PRICES,
  ],
  elements: [
    DOCUMENT_OPTION_DISPLAY_TAX_DETAILS,
    DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY,
    DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE,
    DOCUMENT_OPTION_DISPLAY_PROJECT_NAME,
    DOCUMENT_OPTION_DISPLAY_ASSIGNEE,
    DOCUMENT_OPTION_DISPLAY_ASSIGNEE_EMAIL,
    DOCUMENT_OPTION_DISPLAY_ASSIGNEE_PHONE,
    DOCUMENT_OPTION_DISABLE_QRCODE,
  ],
  situations: [
    DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY,
    DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES,
    DOCUMENT_OPTION_HIDE_SITUATION_UNIT,
  ],
}

export const DOCUMENT_SUBTYPE_CREDIT = 'credit'
export const DOCUMENT_SUBTYPE_CORRECTIVE = 'corrective'
export const DOCUMENT_SUBTYPE_SITUATION = 'situation'
export const DOCUMENT_SUBTYPE_DOWN_PAYMENT = 'down_payment'
export const DOCUMENT_SUBTYPE_FINAL = 'final'

export const DOCUMENT_SUBTYPES = [
  DOCUMENT_SUBTYPE_CREDIT,
  DOCUMENT_SUBTYPE_SITUATION,
  DOCUMENT_SUBTYPE_DOWN_PAYMENT,
  DOCUMENT_SUBTYPE_FINAL,
]

export const LINE_TYPE_GROUP = 'group'
export const LINE_TYPE_PRODUCT = 'product'
export const LINE_TYPE_SITUATION = 'situation'
export const LINE_TYPE_DOWN_PAYMENT = 'down_payment'
export const LINE_TYPE_CREDIT = 'down_payment'
export const LINE_TYPE_TEXT = 'text'
export const LINE_TYPE_PAGE_BREAK = 'page_break'
export const LINE_TYPE_LINE_BREAK = 'line_break'
export const LINE_TYPE_SUBTOTAL = 'subtotal'
export const LINE_TYPE_LEGACY = 'legacy'

export const PRINT_MODE_SIMPLE = 'simple'
export const PRINT_MODE_DUPLEX = 'duplex'

export const PRINT_MODES = [PRINT_MODE_SIMPLE, PRINT_MODE_DUPLEX]

export const PAYMENT_METHOD_CARD = 'card'
export const PAYMENT_METHOD_WIRE = 'wire'
export const PAYMENT_METHOD_CASH = 'cash'
export const PAYMENT_METHOD_CHECK = 'check'
export const PAYMENT_METHOD_PAYBYLINK = 'paybylink'
export const PAYMENT_METHOD_CREDIT = 'credit'
export const PAYMENT_METHOD_DISCOUNT = 'discount'
export const PAYMENT_METHOD_DIRECT_DEBIT = 'direct_debit'
export const PAYMENT_METHOD_LOAN = 'loan'

export const PAYMENT_METHOD_TYPES = [
  PAYMENT_METHOD_WIRE,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_LOAN,
  PAYMENT_METHOD_DIRECT_DEBIT,
]

export const DOWN_PAYMENT_TIME_SIGNATURE = 'signature'
export const DOWN_PAYMENT_TIME_ORDER = 'order'
export const DOWN_PAYMENT_TIME_SOW = 'sow'
export const DOWN_PAYMENT_TIME_HOW = 'how'
export const DOWN_PAYMENT_TIME_CUSTOM = 'custom'

export const DOWN_PAYMENT_TIMES = [
  DOWN_PAYMENT_TIME_SIGNATURE,
  DOWN_PAYMENT_TIME_ORDER,
  DOWN_PAYMENT_TIME_SOW,
  DOWN_PAYMENT_TIME_HOW,
  DOWN_PAYMENT_TIME_CUSTOM,
]

export const TAX_METHOD_LINES = 'lines'
export const TAX_METHOD_SUM = 'sum'

export const AMOUNT_TYPE_PERCENT = 'percent'
export const AMOUNT_TYPE_AMOUNT = 'amount'
export const AMOUNT_TYPE_SUBTOTAL = 'subtotal'
export const AMOUNT_TYPE_TOTAL = 'total'

export const AMOUNT_TYPES = [AMOUNT_TYPE_PERCENT, AMOUNT_TYPE_SUBTOTAL]
export const AMOUNT_TYPES_TOTAL = [AMOUNT_TYPE_PERCENT, AMOUNT_TYPE_TOTAL]

export const DEDUCTION_TYPE_ENERGY = 'energy'
export const DEDUCTION_TYPE_DISCOUNT = 'discount'
export const DEDUCTION_TYPE_DISBURSEMENT = 'disbursement'
export const DEDUCTION_TYPE_PRORATA = 'prorata'
export const DEDUCTION_TYPE_PRICE_ADJUSTMENT = 'adjustment'
export const DEDUCTION_TYPE_DIRECT_PAYMENT = 'direct_payment'
export const DEDUCTION_TYPE_DOWN_PAYMENT = 'down_payment'
export const DEDUCTION_TYPE_OTHER = 'other'

export const DEDUCTION_TYPES_TOTAL = [
  DEDUCTION_TYPE_ENERGY,
  DEDUCTION_TYPE_DIRECT_PAYMENT,
  DEDUCTION_TYPE_DISBURSEMENT,
  DEDUCTION_TYPE_PRORATA,
  DEDUCTION_TYPE_DOWN_PAYMENT,
  DEDUCTION_TYPE_OTHER,
]

export const DEDUCTION_TYPES = [
  DEDUCTION_TYPE_PRICE_ADJUSTMENT,
  DEDUCTION_TYPE_DISCOUNT,
  DEDUCTION_TYPE_PRORATA,
  DEDUCTION_TYPE_DOWN_PAYMENT,
  DEDUCTION_TYPE_OTHER,
]

export const DISCOUNT_TYPE_PERCENT = 'percent'
export const DISCOUNT_TYPE_AMOUNT = 'amount'

export const DISCOUNT_TYPES = [DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_AMOUNT]

export const DURATION_UNIT_HOUR = 'hour'
export const DURATION_UNIT_DAY = 'day'
export const DURATION_UNIT_WEEK = 'week'
export const DURATION_UNIT_MONTH = 'month'

export const DURATION_UNITS = [DURATION_UNIT_HOUR, DURATION_UNIT_DAY, DURATION_UNIT_WEEK, DURATION_UNIT_MONTH]

export const DOCUMENTS_STATE_MACHINE = {
  [QUOTE]: {
    [DOCUMENT_STATUS_DRAFT]: [
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_OPEN,
      DOCUMENT_STATUS_ACCEPTED,
      DOCUMENT_STATUS_REFUSED,
    ],
    [DOCUMENT_STATUS_OPEN]: [
      DOCUMENT_STATUS_OPEN,
      DOCUMENT_STATUS_ACCEPTED,
      DOCUMENT_STATUS_REFUSED,
      DOCUMENT_STATUS_CANCELLED,
    ],
    [DOCUMENT_STATUS_ACCEPTED]: [
      DOCUMENT_STATUS_OPEN,
      DOCUMENT_STATUS_ACCEPTED,
      DOCUMENT_STATUS_REFUSED,
      DOCUMENT_STATUS_CANCELLED,
    ],
    [DOCUMENT_STATUS_REFUSED]: [DOCUMENT_STATUS_OPEN, DOCUMENT_STATUS_ACCEPTED, DOCUMENT_STATUS_REFUSED],
    [DOCUMENT_STATUS_CANCELLED]: [DOCUMENT_STATUS_CANCELLED],
  },
  [INVOICE]: {
    [DOCUMENT_STATUS_DRAFT]: [DOCUMENT_STATUS_DRAFT, DOCUMENT_STATUS_OPEN, DOCUMENT_STATUS_PAID],
    [DOCUMENT_STATUS_OPEN]: [DOCUMENT_STATUS_OPEN, DOCUMENT_STATUS_PAID],
    [DOCUMENT_STATUS_PAID]: [DOCUMENT_STATUS_PAID],
    [DOCUMENT_STATUS_CANCELLED]: [],
  },
}

export const INVOICE_REMINDER_FREQUENCY_DUE_DATE = 'due_date'
export const INVOICE_REMINDER_FREQUENCY_DUE_DATE_AND_CUSTOM = 'due_date_custom'
export const INVOICE_REMINDER_FREQUENCY_CUSTOM = 'custom'

export const QUOTE_REMINDER_7_DAYS = '7_days'

export const QUOTE_REMINDER_EVERY_7_DAYS = 'every_7_days'

export const INVOICE_REMINDER_FREQUENCIES = [
  INVOICE_REMINDER_FREQUENCY_DUE_DATE,
  INVOICE_REMINDER_FREQUENCY_DUE_DATE_AND_CUSTOM,
  INVOICE_REMINDER_FREQUENCY_CUSTOM,
]
