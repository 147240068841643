//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, useFetch, ref, useRouter, onMounted } from '@nuxtjs/composition-api'
import { useLoading, useErrorState } from '@/composables/loading'

import {
  INVOICE_STATUSES,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_REFUSED,
  DOCUMENT_STATUS_CANCELLED,
  INVOICE,
} from '@/constants/documents'

import { PROJECT_COLORS } from '@/constants/projects'
import { get as _get } from 'lodash-es'

export default defineComponent({
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { $bus, $projectsRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()
    const router = useRouter()

    const projects = ref([])

    const { fetch, fetchState } = useFetch(async () => {
      setLoading(true, 'contact_invoices')
      try {
        const { data } = await $projectsRepository.list({
          params: {
            _limit: 25,
            _page: 1,
            customer: props.contact.id,
          },
        })
        projects.value = data
      } catch (err) {
      } finally {
        setLoading(false, 'contact_invoices')
      }
    })

    const itemPage = ({ $event, item }) => {
      if ($event.target.closest('.no-navigation') === null && !$event.target.classList.contains('no-navigation')) {
        $bus.emit('closeContact')
        router.push(`/projects/${item.id}`)
      }
    }

    onMounted(() => {
      $bus.on('delete.item', ({ object, type }) => {
        const index = projects.value.findIndex((el) => el.id === object.id)
        if (index !== -1) {
          projects.value.splice(index, 1)
        }
      })
    })

    return {
      _get,
      props,
      PROJECT_COLORS,
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_REFUSED,
      DOCUMENT_STATUS_CANCELLED,
      isLoading,
      hasErrorState,
      projects,
      itemPage,
    }
  },
})
