import { get as _get } from 'lodash-es'
import { useUser } from '@/composables/user'
import { useContext, computed } from '@nuxtjs/composition-api'

export default function () {
  const { app } = useContext()
  const { planItems, company } = useUser()

  const getDefaultPlanItem = (productType) => {
    if (!planItems) return null
    return (
      planItems.find(
        (item) => item.code.toString() === _get(company.value, `defaultProductPlanItems.${productType}`, '').toString()
      ) || null
    )
  }

  const getPlanItemOptions = (productType) => {
    if (!planItems)
      return [
        {
          value: null,
          label: app.i18n.t('products.field.placeholder.defaultPlanItem', {
            defaultPlanItem: getDefaultPlanItem(productType),
          }),
        },
      ]

    return planItems.reduce(
      (arr, item) => [...arr, ...[{ value: item.id, label: `${item.name}` }]],
      [
        {
          value: null,
          label: app.i18n.t('products.field.placeholder.defaultPlanItem', {
            defaultPlanItem: getDefaultPlanItem(productType),
          }),
        },
      ]
    )
  }

  const getPlanItemName = (type) => {
    let planItem = planItems.find((el) => el.type === type)
    if (planItem) {
      return planItem.name
    }
    return null
  }

  return { getDefaultPlanItem, getPlanItemOptions, getPlanItemName }
}
