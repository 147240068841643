//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import * as constants from '@/constants/documents'
import { statusClasses } from '@/config/documents'

import {
  DOCUMENT_STATUS_PAID,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENTS_STATE_MACHINE,
  CREDIT,
  DOCUMENT_STATUS_TO_REVIEW,
  DOCUMENT_STATUS_TO_PAY,
  DOCUMENT_STATUS_SCHEDULED,
} from '@/constants/documents'

import { defineComponent, useContext, computed } from '@nuxtjs/composition-api'
import { useErrorState, useLoading } from '@/composables/loading'
import { useStatus } from '@/composables/document'
import { pluralizeType } from '@/api/resources'
import { useUser } from '@/composables/user'

export default defineComponent({
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: () => ({}),
    },
    labelClass: {
      type: String,
      default: 'px-3 py-1',
    },
    type: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { app, $bus, $getRepository } = useContext()
    const { setLoading, isLoading } = useLoading()
    const { setErrorState, hasErrorState } = useErrorState()
    const { can } = useUser()

    const pluralType = computed(() => pluralizeType[props.type || props.document.objectType || props.document.object])

    const statusClass = computed(() => _get(statusClasses, `${props.document.object}.${props.document.status}`, ''))
    const getStatusClass = (status) => _get(statusClasses, `${props.document.object}.${status}`, '')

    const statusIcon = {
      [constants.DOCUMENT_STATUS_DRAFT]: ['fal', 'file'],
      [constants.DOCUMENT_STATUS_OPEN]: ['fal', 'clock'],
      [constants.DOCUMENT_STATUS_ACCEPTED]: ['fal', 'thumbs-up'],
      [constants.DOCUMENT_STATUS_REFUSED]: ['fal', 'thumbs-down'],
      [constants.DOCUMENT_STATUS_PAID]: ['fal', 'coins'],
      [constants.DOCUMENT_STATUS_CANCELLED]: ['fal', 'ban'],
    }

    const availableStatuses = computed(() =>
      _get(DOCUMENTS_STATE_MACHINE, `${props.document.object}.${props.document.status}`, []).filter(
        (el) => el !== props.document.status
      )
    )

    const hasDropdown = computed(
      () =>
        !props.locked &&
        ![CREDIT].includes(props.document.objectType) &&
        ![DOCUMENT_STATUS_CANCELLED, DOCUMENT_STATUS_DRAFT, DOCUMENT_STATUS_PAID].includes(props.document.status)
    )

    const handleToggle = function (toggle) {
      if (
        props.locked === false &&
        ![DOCUMENT_STATUS_CANCELLED, DOCUMENT_STATUS_DRAFT, DOCUMENT_STATUS_PAID].includes(props.document.status) &&
        ![CREDIT].includes(props.document.objectType)
      ) {
        toggle()
      }
    }

    const { setStatus } = useStatus($getRepository(props.document))

    const changeStatus = async function (status, hide) {
      try {
        setLoading(true, status)
        const data = await setStatus(props.document, status)
        hide()
        $bus.emit('update.item', { data, type: props.document.objectType })
        emit('updateStatus', data)
      } catch (err) {
        setErrorState(status)
      } finally {
        setLoading(false, status)
      }
    }

    return {
      can,
      CREDIT,
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_PAID,
      DOCUMENT_STATUS_CANCELLED,
      props,
      pluralType,
      statusClass,
      statusIcon,
      hasDropdown,
      handleToggle,
      changeStatus,
      getStatusClass,
      availableStatuses,
      isLoading,
      hasErrorState,
    }
  },
})
