//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, useFetch, ref, useRouter, onMounted } from '@nuxtjs/composition-api'
import { useLoading, useErrorState } from '@/composables/loading'

import {
  INVOICE_STATUSES,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_REFUSED,
  DOCUMENT_STATUS_CANCELLED,
  INVOICE,
  CREDIT,
} from '@/constants/documents'
import { get as _get } from 'lodash-es'

export default defineComponent({
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { $bus, $invoicesRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()
    const router = useRouter()

    const invoices = ref([])

    const { fetch, fetchState } = useFetch(async () => {
      setLoading(true, 'contact_invoices')
      try {
        const { data } = await $invoicesRepository.list({
          params: {
            _limit: 25,
            _page: 1,
            status: INVOICE_STATUSES,
            customer: props.contact.id,
            type: CREDIT,
          },
        })
        invoices.value = data
      } catch (err) {
      } finally {
        setLoading(false, 'contact_invoices')
      }
    })

    const itemPage = ({ $event, item }) => {
      if ($event.target.closest('.no-navigation') === null && !$event.target.classList.contains('no-navigation')) {
        $bus.emit('closeContact')
        router.push(`/invoices/${item.id}${item.status !== DOCUMENT_STATUS_DRAFT ? '/details' : ''}`)
      }
    }

    const createDocument = async function () {
      try {
        setLoading(true, 'create_invoice')

        const { data } = await $invoicesRepository.create({
          type: INVOICE,
          customer: props.contact.id,
        })

        const id = _get(data, 'id', null)
        if (id) {
          await router.push(`/invoices/${id}`)
        }
      } catch (err) {
        setErrorState()
        setLoading(false, 'create_invoice', 2000)
      }
    }

    onMounted(() => {
      $bus.on('delete.item', ({ object, type }) => {
        const index = invoices.value.findIndex((el) => el.id === object.id)
        if (index !== -1) {
          invoices.value.splice(index, 1)
        }
      })
    })

    return {
      props,
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_REFUSED,
      DOCUMENT_STATUS_CANCELLED,
      isLoading,
      hasErrorState,
      invoices,
      itemPage,
      createDocument,
    }
  },
})
