import { get as _get, has as _has, set as _set, isEqual as _isEqual, mergeWith as _mergeWith } from 'lodash-es'
import { getRandomString } from '@/utils/documents'

const apiParams = [
  'primaryColor',
  'customPrimaryColor',
  'fontFamily',
  'fontSize',
  'logoSize',
  'logoCentered',
  'projectAddressRight',
  'windowedEnvelope',
  'logoPosition',
  'logo',
  'tableStyle',
  'whiteLabel',
  'theme',
  'options',
  'customerOptions',
  'companyOptions',
  'labels',
  'labelsSize',
  'labelsPosition',
]

const convertParams = (params) =>
  apiParams.reduce((obj, prop) => {
    if (_has(params, prop)) {
      let val = params[prop]
      if (prop === 'labels') {
        // Flattens label API response to match app data format
        val = val.map((el) => {
          let label = el.label
          label.key = getRandomString(4)
          return label
        })
      }
      obj[prop] = val
    }
    return obj
  }, {})

const getDefaultData = () => ({
  logo: null,
  signature: null,
  labels: [],
  labelsSize: 32,
  labelsPosition: 'left',
  fontSize: 'normal',
  options: {
    row_border_h: false,
    row_border_v: false,
    row_contrast: false,
    rounded_borders: false,
    wrap_subtotal: false,
  },
  customerOptions: {
    display_email: false,
    display_phone: false,
  },
  companyOptions: {
    hide_name: false,
    hide_address: false,
    hide_email: false,
    hide_phone: false,
    hide_website: false,
  },
  theme: 'pro',
  primaryColor: 'darkgray',
  customPrimaryColor: null,
  fontFamily: 'Arial',
  logoSize: 60,
  logoPosition: 'top',
  projectAddressRight: false,
  windowedEnvelope: false,
  logoCentered: false,
  tableStyle: 'rows',
  whiteLabel: false,
})

export const state = () => ({
  init: false,
  dirty: false,
  id: null,
  prevData: getDefaultData(),
  data: getDefaultData(),
  terms: null,
  termsDocuments: [],
  termsPrintMode: null,
})

export const getters = {
  getTemplate: (state) => ({
    logo: state.data.logo,
    labels: state.data.labels,
    labelsSize: state.data.labelsSize,
    labelsPosition: state.data.labelsPosition,
    logoSize: state.data.logoSize,
    fontSize: state.data.fontSize,
    logoPosition: state.data.logoPosition,
    projectAddressRight: state.data.projectAddressRight,
    windowedEnvelope: state.data.windowedEnvelope,
    logoCentered: state.data.logoCentered,
    fontFamily: state.data.fontFamily,
    primaryColor: state.data.primaryColor,
    customPrimaryColor: state.data.customPrimaryColor,
    options: state.data.options,
    customerOptions: state.data.customerOptions,
    companyOptions: state.data.companyOptions,
    theme: state.data.theme,
    tableStyle: state.data.tableStyle,
    whiteLabel: state.data.whiteLabel,
  }),
  getTerms: (state) => ({
    terms: state.terms,
    termsDocuments: state.termsDocuments,
    termsPrintMode: state.termsPrintMode,
  }),
  getOptions: (state) => _get(state.data, 'options', {}),
  getOption: (state) => (key) => _get(state.data.options, key, false),
  getCustomerOptions: (state) => _get(state.data, 'customerOptions', {}),
  getCompanyOptions: (state) => _get(state.data, 'companyOptions', {}),
  getCustomerOption: (state) => (key) => _get(state.data.customerOptions, key, false),
  getCompanyOption: (state) => (key) => _get(state.data.companyOptions, key, false),
  getData: (state) => (key) => _get(state.data, key, undefined),
  isDirty: (state) => {
    return !_isEqual(state.data, state.prevData)
  },
}

export const mutations = {
  setInit(state, value) {
    state.init = value
  },
  setDirty(state, value) {
    state.dirty = value
  },
  initData(state, params) {
    state.data = { ...convertParams(params) }
    state.prevData = { ...convertParams(params) }

    state.id = params.id
  },
  setData(state, params) {
    if (_has(state.data, params.key) && params.key) {
      _set(state.data, params.key, params.val)
      state.dirty = true
    }
  },
  setTerms(state, terms) {
    state.terms = terms
  },
  setTermsDocuments(state, termsDocuments) {
    state.termsDocuments = termsDocuments
  },
  setTermsPrintMode(state, termsPrintMode) {
    state.termsPrintMode = termsPrintMode
  },
  setOption(state, params) {
    let options = { ...state.data.options }
    _set(options, params.key, params.val)
    state.data.options = options
  },
  setCustomerOption(state, params) {
    let options = { ...state.data.customerOptions }
    _set(options, params.key, params.val)
    state.data.customerOptions = options
  },
  setCompanyOption(state, params) {
    let options = { ...state.data.companyOptions }
    _set(options, params.key, params.val)
    state.data.companyOptions = options
  },
  initState(state) {
    state.init = false
    state.dirty = false
    state.id = null
    state.prevData = getDefaultData()
    state.data = getDefaultData()
  },
  resetState(state) {
    state = _mergeWith(state, {
      data: state.prevData,
    })
  },
}

export const actions = {
  async load({ commit, state }) {
    /*try {
      const { data } = await this.$companiesRepository.find(this.$auth.company.value.id, { _expand: ['terms'] })
      commit('setTerms', data.terms)
      commit('setTermsDocuments', data.termsDocuments)
      commit('setTermsPrintMode', data.termsPrintMode)
    } catch (err) {
      console.log(err)
    }*/

    if (!state.init) {
      try {
        const { data } = await this.$axios.get('api/templates/default', { params: { _expand: ['logo', 'labels'] } })
        commit('initData', data.data)
        commit('setInit', true)
      } catch (err) {
        console.log(err)
      }
    }
  },

  cancel({ commit, state }) {
    commit('resetState')
  },

  async save({ getters, commit, state }, params = { notify: true }) {
    try {
      const template = JSON.parse(
        JSON.stringify(getters.getTemplate, (key, val) => {
          switch (true) {
            case key === 'logo':
              if (_has(val, 'id')) {
                return val.id
              }
              return null
            case key === 'logoSize':
              return parseInt(val)
            case key === 'labels':
              return val.map((el) => el.id)
            default:
              return val
          }
        })
      )

      if (template.primaryColor === 'custom' && template.customPrimaryColor === null) {
        template.primaryColor = 'brand'
      }

      const { data } = await this.$axios.put(`api/templates/${state.id}?_expand[]=logo&_expand[]=labels`, template)
      commit('initData', data.data)

      if (params.notify) {
        try {
          this.$toast.show(this.$i18n.t('templating.notice.saved'))
        } catch (err) {
          console.log(err)
        }
      }

      commit('setDirty', false)
      return true
    } catch (err) {
      return false
    }
  },
}
