var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"isOpen":_vm.isModalOpen(),"content-classes":"sm:pt-32"}},[[(_vm.item.element)?_c('div',{staticClass:"modal-inner w-full max-w-xl"},[_c('div',{staticClass:"p-4 sm:p-6 sm:pt-5"},[_c('ModalCloseButton',{nativeOn:{"click":function($event){return _vm.closeModal()}}}),_vm._v(" "),_c('h3',{staticClass:"font-medium text-lg mb-4"},[_vm._v("Options avancées")]),_vm._v(" "),_c('FormulateForm',[_c('div',{staticClass:"space-y-5"},[_c('FormulateInput',{attrs:{"type":"select","name":"type","label":_vm.$t('products.field.label.type'),"label-class":['font-medium text-sm'],"options":_vm.availableTypes.reduce(
                  function (arr, type) { return arr.concat( [
                      {
                        value: type,
                        label: _vm.$t(("products.label.types." + type)),
                        disabled: type === _vm.PRODUCT_TYPE_MISC,
                      } ] ); },
                  []
                )},on:{"input":_vm.handleType},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_vm._v(" "),(_vm.company.accountingMode !== _vm.ACCOUNTING_MODE_DISABLED)?_c('FormulateInput',{attrs:{"type":"select","name":"type","label":_vm.$t('products.field.label.planItem'),"placeholder":_vm.$t('products.field.placeholder.defaultPlanItem', { defaultPlanItem: _vm.defaultPlanItem }),"label-class":['font-medium text-sm'],"options":_vm.planItemOptions},model:{value:(_vm.form.planItem),callback:function ($$v) {_vm.$set(_vm.form, "planItem", $$v)},expression:"form.planItem"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"flex mt-6"},[_c('Button',{staticClass:"ml-auto",attrs:{"minimal":"","color":"gray"},on:{"clicked":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('layout.action.cancel')))]),_vm._v(" "),_c('Button',{staticClass:"ml-2",attrs:{"color":"brand"},on:{"clicked":_vm.update}},[_vm._v(_vm._s(_vm.$t('layout.action.save')))])],1)],1)]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }