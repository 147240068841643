//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, provide, nextTick, computed, ref } from '@nuxtjs/composition-api'
import { sidebar } from '~/config/layout'
import { useUser } from '@/composables/user'
import { get as _get } from 'lodash-es'
import { withQuery } from 'ufo'
import useMq from '@/composables/mq'
export default defineComponent({
  props: {
    sidebarOpen: Boolean,
  },
  setup(props, { emit }) {
    const versionNumber = '1.8.83'
    const { user, company, subscription, can, isRoot } = useUser()
    const { mq } = useMq()
    const isStarter = computed(() => _get(subscription.value, 'plan.slug', null) === 'starter')

    const sidebarMap = sidebar
      .reduce((arr, el) => {
        arr = [].concat(arr, el.items)
        return arr
      }, [])
      .reduce((obj, val) => {
        if (val.items) {
          for (const child of val.items) {
            obj[child.key] = val.key
          }
        }
        return obj
      }, {})

    const handleClickOutside = async ($event) => {
      if ($event.target.closest('.v-popper__popper') === null) {
        await nextTick()
        if (props.sidebarOpen) {
          emit('closeSidebar')
        }
      }
    }

    const switchCompany = (company) => {
      window.location.href = withQuery(window.location.href.replace('?invites=review', ''), {
        switch_company: company.id,
      })
    }

    provide('sidebarMap', sidebarMap)

    const companies = computed(() => _get(user.value, 'companies', []))

    const companyName = ref(null)

    const hasEllipsis = computed(() => {
      if (companyName.value) {
        return companyName.value.offsetWidth < companyName.value.scrollWidth
      }
      return false
    })

    const filteredSidebar = computed(() =>
      sidebar
        .reduce((arr, group) => {
          let currentGroup = group
          let showGroup = true

          if (typeof group.visible === 'function' && !group.visible(company, isRoot, can)) showGroup = false
          if (group.preview === true && !isRoot()) showGroup = false

          if (showGroup) {
            currentGroup.items = group.items.filter((el) => {
              if (el.preview === true && !isRoot()) return false
              if (!isRoot() && el.flag && !company.value.flags.includes(el.flag)) return false
              if (typeof el.visible === 'function') return el.visible(company, isRoot, can)
              if (el.granted === true) return true

              return (
                can('role', `${el.key}.read`) ||
                (can('role', `documents.read`) &&
                  ['quotes', 'invoices', 'credits', 'order_forms', 'supplier_invoices'].includes(el.key))
              )
            })

            arr.push(currentGroup)
          }

          return arr
        }, [])
        .filter((el) => el.items.length > 0)
    )

    return {
      props,
      versionNumber,
      company,
      companies,
      companyName,
      hasEllipsis,
      user,
      isStarter,
      filteredSidebar,
      sidebar,
      switchCompany,
      handleClickOutside,
    }
  },
})
