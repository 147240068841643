//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { withQuery } from 'ufo'
import { get as _get, has as _has } from 'lodash-es'
import {
  defineComponent,
  computed,
  ref,
  nextTick,
  onMounted,
  useContext,
  onBeforeUnmount,
  useRouter,
  useRoute,
  provide,
} from '@nuxtjs/composition-api'

import useLayout from '@/composables/layout'
import useOnboarding from '@/composables/onboarding'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useGetters, useState } = createNamespacedHelpers('')
import { useErrorState, useLoading } from '@/composables/loading'
import { CONTACT_TYPES } from '@/constants/contacts'
import { CREDIT, INVOICE } from '@/constants/documents'
import { useUser } from '@/composables/user'

export default defineComponent({
  props: {
    layout: {
      type: String,
      default: 'default',
    },
  },
  setup(props) {
    const { mustUpgrade, targetVersion } = useState(['mustUpgrade', 'targetVersion'])

    const { user, company } = useUser()
    const { $bus, $quotesRepository, $orderFormsRepository } = useContext()
    const router = useRouter()
    const route = useRoute()
    const { setLoading } = useLoading()
    const { setErrorState } = useErrorState()

    const layout = useLayout()
    const onboarding = useOnboarding()

    const contactSidePanel = ref(null)
    const helpSidePanel = ref(null)
    const currentContact = ref(null)

    const holdbackSidePanel = ref(null)
    const currentHoldback = ref(null)

    const openContact = async (contact) => {
      currentContact.value = contact.id
      await nextTick()
      contactSidePanel.value.openModal()
    }

    const closeContact = async (contact) => {
      contactSidePanel.value.closeModal()
    }

    const openHelp = () => helpSidePanel.value.openModal()

    const openChat = () => {
      if (_has(window, '$crisp')) {
        window.$crisp.push(['do', 'chat:show'])

        window.$crisp.push(['set', 'user:email', [user.value.email]])

        if (_get(user.value, 'phone', null)) {
          window.$crisp.push(['set', 'user:phone', [user.value.phone]])
        }

        window.$crisp.push(['set', 'user:company', company.value.name])

        if (`${_get(user.value, 'firstName', '')} ${_get(user.value, 'lastName', '')}`.trim() !== '') {
          window.$crisp.push([
            'set',
            'user:nickname',
            [`${_get(user.value, 'firstName', '')} ${_get(user.value, 'lastName', '')}`],
          ])
        }

        window.$crisp.push(['do', 'chat:open'])
      }
    }

    const inviteModal = ref(null)
    const showInvite = ref(false)

    const plansModal = ref(null)
    const bcModal = ref(null)

    const createProjectModal = ref(null)
    const createProjectCategory = ref(null)
    const createProjectCustomer = ref(null)
    const createProductModal = ref(null)
    const createProductCategory = ref(null)
    const createProductType = ref(null)
    const createContactModal = ref(null)
    const createContactType = ref(null)
    const createContactData = ref(null)
    const createContactAllowTypes = ref([])
    const currentProduct = ref(null)
    const currentProductId = ref(null)
    const productViewMode = ref('create')

    const supplierInvoiceSidePanel = ref(null)
    const currentSupplierInvoice = ref(null)

    const addPaymentModal = ref(null)
    const currentInvoice = ref(null)

    const createInvoiceModal = ref(null)
    const createInvoiceType = ref(INVOICE)

    const createTaskModal = ref(null)
    const currentTaskType = ref('task')
    const currentTaskParent = ref(null)
    const currentProject = ref(null)
    const currentTask = ref(null)
    const currentTaskId = ref(null)
    const taskViewMode = ref('create')

    const idempotencyErrorModal = ref(null)
    const idempotencyErrorMessage = ref(null)

    const expenseModal = ref()
    const invoiceModal = ref()
    const currentEditSupplierInvoice = ref(null)
    const currentExpense = ref(null)

    const createQuote = async function () {
      try {
        setLoading(true, 'create_quote')

        const { data } = await $quotesRepository.create()

        const id = _get(data, 'id', null)
        if (id) {
          await router.push(`/quotes/${id}`)
        }
      } catch (err) {
        setErrorState('create_quote')
      } finally {
        setLoading(false, 'create_quote', 2000)
      }
    }

    const createOrderForm = async function (params = {}) {
      try {
        setLoading(true, 'create_order_form')
        const { data } = await $orderFormsRepository.create(params)
        const id = _get(data, 'id', null)
        if (id) {
          await router.push(`/order-forms/${id}`)
        }
      } catch (err) {
        setErrorState('create_order_form')
      } finally {
        setLoading(false, 'create_order_form', 2000)
      }
    }

    const isDragging = ref(false)
    provide('isDragging', isDragging)
    let timeout = null

    const handleDragOver = (event) => {
      event.preventDefault()
      isDragging.value = true
      clearTimeout(timeout)
      timeout = setTimeout(() => (isDragging.value = false), 1000)
    }

    onMounted(async () => {
      $bus.on('idempotencyError', async (message) => {
        idempotencyErrorMessage.value = message
        await nextTick()
        if (idempotencyErrorModal.value) {
          idempotencyErrorModal.value.openModal()
        }
      })

      $bus.on('openSupplierInvoice', async (invoice) => {
        currentSupplierInvoice.value = invoice.id

        await nextTick()
        if (supplierInvoiceSidePanel.value) {
          supplierInvoiceSidePanel.value.openModal()
        }
      })

      $bus.on('openHoldback', async (invoice) => {
        currentHoldback.value = invoice.id
        await nextTick()
        if (holdbackSidePanel.value) {
          holdbackSidePanel.value.openModal()
        }
      })

      $bus.on('openContact', (contact) => openContact(contact))
      $bus.on('closeContact', (contact) => closeContact(contact))
      $bus.on('openHelp', () => openHelp())
      $bus.on('openChat', () => openChat())
      $bus.on('openBcModal', () => bcModal.value.openModal())
      $bus.on('openPlans', () => plansModal.value.openModal())
      $bus.on('closePlans', () => plansModal.value.closeModal())

      $bus.on('createTask', async ({ project, type, parent }) => {
        currentProject.value = project
        currentTaskType.value = type
        currentTaskParent.value = parent
        taskViewMode.value = 'create'
        currentTask.value = null
        currentTaskId.value = null
        await nextTick()
        createTaskModal.value.openModal()
      })

      $bus.on('viewTask', async (task) => {
        taskViewMode.value = 'update'
        currentTask.value = null
        currentTaskId.value = null

        if (_has(task, 'id')) {
          currentTaskId.value = task.id
        } else {
          currentTask.value = task
        }

        await nextTick()
        createTaskModal.value.openModal()
      })

      $bus.on('createQuote', createQuote)
      $bus.on('createOrderForm', (params) => createOrderForm(params))

      $bus.on('createInvoice', () => {
        createInvoiceType.value = INVOICE
        createInvoiceModal.value.openModal()
      })

      $bus.on('createCredit', () => {
        createInvoiceType.value = CREDIT
        createInvoiceModal.value.openModal()
      })

      $bus.on('createProject', async (params = { category: null, customer: null }) => {
        createProjectCategory.value = params.category
        createProjectCustomer.value = params.customer
        await nextTick()
        if (createProjectModal.value) {
          createProjectModal.value.openModal()
        }
      })

      $bus.on('createContact', async ({ type, allowTypes, contact }) => {
        if (type) {
          createContactType.value = type
        }

        createContactData.value = null

        if (contact) {
          createContactData.value = contact
        }

        if (allowTypes) {
          createContactAllowTypes.value = allowTypes
        } else {
          createContactAllowTypes.value = CONTACT_TYPES
        }
        await nextTick()
        createContactModal.value.openModal()
      })

      $bus.on('createProduct', async ({ type, category }) => {
        if (type) {
          createProductType.value = type
          createProductCategory.value = category
        }

        productViewMode.value = 'create'
        currentProduct.value = null
        currentProductId.value = null
        await nextTick()
        if (createProductModal.value) {
          createProductModal.value.openModal()
        }
      })

      $bus.on('viewProduct', async (item) => {
        productViewMode.value = item.mode || 'update'
        // Used when product comes from Meilisearch and loads it in the Modal
        if (_has(item, 'uuid')) {
          currentProduct.value = null
          currentProductId.value = item.uuid
        } else {
          currentProductId.value = null
          currentProduct.value = item
        }

        await nextTick()
        if (createProductModal.value) {
          createProductModal.value.openModal()
        }
      })

      $bus.on('addPayment', async (invoice) => {
        currentInvoice.value = invoice
        await nextTick()
        if (addPaymentModal.value) addPaymentModal.value.openModal()
      })

      $bus.on('editSupplierInvoice', async (data) => {
        currentEditSupplierInvoice.value = data
        await nextTick()
        if (invoiceModal.value) invoiceModal.value.openModal()
      })

      $bus.on('editSupplierExpense', async (data) => {
        currentExpense.value = data
        await nextTick()
        if (expenseModal.value) expenseModal.value.openModal()
      })

      if (_get(route.value, 'query.invites', null)) {
        showInvite.value = true
        await nextTick()
        if (inviteModal.value) {
          inviteModal.value.openModal()
        }
      }

      window.document.addEventListener('dragover', handleDragOver, false)
    })

    onBeforeUnmount(() => {
      $bus.off('addPayment')
      $bus.off('idempotencyError')
      $bus.off('openContact')
      $bus.off('closeContact')
      $bus.off('openSupplierInvoice')
      $bus.off('openHoldback')
      $bus.off('openHelp')
      $bus.off('openChat')
      $bus.off('openBcModal')
      $bus.off('openPlans')
      $bus.off('closePlans')
      $bus.off('closePlans')
      $bus.off('createProduct')
      $bus.off('createProject')
      $bus.off('createContact')
      $bus.off('createQuote')
      $bus.off('createOrderForm')
      $bus.off('createInvoice')
      $bus.off('createCredit')
      $bus.off('createTask')
      $bus.off('viewProduct')
      $bus.off('editSupplierInvoice')
      $bus.off('editSupplierExpense')

      window.document.removeEventListener('dragover', handleDragOver)
    })

    const reloadPage = () => (window.location.href = withQuery(window.location.href, { v: targetVersion.value }))

    return {
      props,
      ...layout,
      ...onboarding,
      mustUpgrade,
      supplierInvoiceSidePanel,
      currentSupplierInvoice,
      currentContact,
      createContactModal,
      createContactType,
      createContactData,
      createContactAllowTypes,
      currentProject,
      contactSidePanel,
      helpSidePanel,
      createProductType,
      createProductCategory,
      currentProduct,
      currentProductId,
      productViewMode,
      addPaymentModal,
      currentInvoice,
      createProductModal,
      createProjectModal,
      createProjectCategory,
      createProjectCustomer,
      createInvoiceModal,
      createInvoiceType,
      createTaskModal,
      currentTask,
      currentTaskType,
      currentTaskParent,
      holdbackSidePanel,
      currentHoldback,
      idempotencyErrorModal,
      idempotencyErrorMessage,
      currentTaskId,
      taskViewMode,
      plansModal,
      bcModal,
      inviteModal,
      showInvite,
      company,
      expenseModal,
      invoiceModal,
      currentEditSupplierInvoice,
      currentExpense,
      reloadPage,
    }
  },
})
