import { render, staticRenderFns } from "./DocumentBuilderWorkItemsDetailed.vue?vue&type=template&id=5917c216&"
import script from "./DocumentBuilderWorkItemsDetailed.vue?vue&type=script&lang=js&"
export * from "./DocumentBuilderWorkItemsDetailed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toggle: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Toggle.vue').default,DocumentBuilderWorkItemsLine: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/document/builder/DocumentBuilderWorkItemsLine.vue').default,ProductWorkItemModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/product/ProductWorkItemModal.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,Tabs: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Tabs.vue').default,RadioButtons: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/RadioButtons.vue').default,CurrencyInput: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/CurrencyInput.vue').default})
