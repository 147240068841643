//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {get as _get} from 'lodash-es'
import {withQuery} from 'ufo'
import useModal from '@/composables/modal'
import {useErrorState, useLoading} from '@/composables/loading'
import {computed, defineComponent, ref, useContext, useRoute} from '@nuxtjs/composition-api'
import {useUser} from '@/composables/user'
import {plansColor} from '@/config/plans'
import {
  TYPE_PRIVATE_LIMITED,
  TYPE_PUBLIC_LIMITED,
  TYPE_PUBLIC_UNLISTED,
  TYPE_SOLE_SHAREHOLDER_LIMITED
} from "@/constants/companies";

export default defineComponent({
  setup(props, { emit }) {
    const modal = useModal()

    const { $getRepository, $axios, app, env } = useContext()
    const appURL = env.APP_URL
    const route = useRoute()

    const { isLoading, setLoading } = useLoading()
    const { hasErrorState } = useErrorState()

    const { company, subscription } = useUser()

    const group = ref(
        ['business_plus', 'enterprise'].includes(_get(subscription.value, 'plan.slug', null)) ||
        [TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED, TYPE_SOLE_SHAREHOLDER_LIMITED].includes(company.value.type) ? 'business' : 'solo')

    const interval = ref('year')
    const users = ref(
      [2, 5, 10].includes(_get(subscription.value, 'plan.users', 2)) ? _get(subscription.value, 'plan.users', 2) : 2
    )

    const hasCurrentPlan = (name) =>
      (name !== 'business_plus' && _get(subscription.value, 'plan.slug', null) === name) ||
      (name === 'business_plus' &&
        _get(subscription.value, 'plan.slug', null) === name &&
        users.value === _get(subscription.value, 'plan.users', null))

    const faqItems = ref(
      ['why_yearly', 'stop', 'payment_methods', 'payment_secure', 'vat_included', 'support_included', 'import_data'].reduce((obj, item) => {
        obj[item] = false
        return obj
      }, {})
    )

    const plans = {
      starter: {
        pricing: {
          year: 0,
          month: 0,
        },
        save: false,
        mainFeatures: ['quotes', 'products', 'contacts'],
      },
      pro: {
        pricing: {
          year: 15000,
          month: 1500,
          je: {
            year: 10000,
            month: 1000
          }
        },
        users: '1 utilisateur',
        save: true,
        upgradeFrom: 'starter',
        mainFeatures: [
          'projects',
          'down_payments',
          'profits',
          'total_deductions',
          'options',
          'payments',
          'custom_documents',
          'whitelabel',
          'vat_attestations',
          'custom_emails',
          'email_documents',
          'clients_portal',
          'export',
          'incomes_book',
          'training',
          'assistance'
        ],
        tooltips: {
          clients_portal: `Le portail client permet à vos clients de consulter leurs documents en ligne, signer les devis électroniquement, retrouver vos coordonnées...`
        },
        icons: {
          training: '💼',
          assistance: '🛟',
        },
        upcoming: [],
        new: ['incomes_book'],
      },
      business: {
        pricing: {
          year: 25000,
          month: 2500,
          je: {
            year: 20000,
            month: 2000
          }
        },
        users: '1 utilisateur + 1 accès comptable',
        save: true,
        upgradeFrom: 'pro',
        mainFeatures: [
          'calendar',
          'projects_planning',
          'situation_invoice',
          'holdbacks',
          'subtotal_deductions',
          'document_images',
          'attachments',

          'unpaid',
          'suppliers',
          'supplier_invoices',
          'order_forms',
          'stock',
          'certificates',
          'accounting',
          'priority_assistance'
        ],
        icons: {
          priority_assistance: '🛟',
        },
        upcoming: ['express_payment'],
        new: ['certificates', 'accounting'],
      },
      business_plus: {
        pricing: {
          users: {
            2: {
              year: 35000,
              month: 3500,
              je: {
                year: 30000,
                month: 3000
              }
            },
            5: {
              year: 45000,
              month: 4500,
              je: {
                year: 40000,
                month: 4000
              }
            },
            10: {
              year: 60000,
              month: 6000,
              je: {
                year: 55000,
                month: 5500
              }
            },
          },
        },
        save: true,
        upgradeFrom: 'business',
        mainFeatures: ['multi_users', 'ocr', 'bank', 'quotes_history', 'dpgf'],
        tooltips: {
          quotes_history: 'Les 10 dernières versions de vos devis sont stockées.',
          emails_history: 'Les emails envoyés sont stockés pendant 1 an.',
        },
        upcoming: ['express_payment', 'bank'],
        new: ['dpgf'],
        subscriptionGifts: ['signature_10', 'online_payment_10'],
      },
    }

    const commonFeatures = ['electronic_signature', 'online_payment', 'batiprix']
    const newFeatures = []


    const availablePlans = computed(() =>
      Object.entries(plans).reduce((obj, [k, v]) => {

        if (group.value === 'solo') {
          if (['pro', 'business', 'business_plus'].includes(k)) {
            obj[k] = v
          }
        } else {
          if (['pro', 'business', 'business_plus'].includes(k)) {
            obj[k] = v
          }
        }

        return obj
      }, {})
    )

    const selectPlan = async (plan) => {
      try {
        setLoading(true, `select_plan_${plan}`)
        const { data } = await $axios.post(`api/billing/subscribe`, {
          plan,
          interval: interval.value,
          users: plan === 'business_plus' ? users.value : undefined,
          cancel_url: withQuery(`${appURL}${route.value.fullPath}`, { payment_cancel: 1 }),
          success_url: withQuery(`${appURL}`, { payment_success: 1 }),
        })
        const url = _get(data, 'data.url', null)

        if (url !== null) {
          window.location.href = url
        } else {
          app.$toast.error('stripe error', {
            duration: 2000,
          })
        }
      } catch (err) {
      } finally {
        setLoading(false, `select_plan_${plan}`)
      }
    }

    const managePlan = async (name) => {
      try {
        setLoading(true, `manage_plan_${name}`)
        const { data } = await $axios.get('api/billing/portal')
        const url = _get(data, 'data.url', null)

        if (url !== null) {
          window.location.href = url
        } else {
          app.$toast.error('stripe error', {
            duration: 2000,
          })
        }
      } catch (err) {
      } finally {
        setLoading(false, `manage_plan_${name}`)
      }
    }

    const getJEPrice = (plan) => {

      if (plan.pricing.users) {
        if (interval.value === 'year') return plan.pricing.users[users.value].je[interval.value] / 12
        else return plan.pricing.users[users.value].je[interval.value]
      } else {
        if (interval.value === 'year') return plan.pricing.je[interval.value] / 12
        else return plan.pricing.je[interval.value]
      }


    }

    return {
      props,
      ...modal,
      app,
      users,
      availablePlans,
      commonFeatures,
      newFeatures,
      plans,
      plansColor,
      group,
      interval,
      company,
      subscription,
      faqItems,
      getJEPrice,
      hasCurrentPlan,
      selectPlan,
      managePlan,
      isLoading,
      hasErrorState,
      _get,
    }
  },
})
