import Vue from 'vue'
import { isSameYear, format, differenceInCalendarDays } from 'date-fns'
import { fr } from 'date-fns/locale'
import { get as _get } from 'lodash-es'

Vue.config.performance = true

Vue.filter('civility', (val) => (val ? val.toUpperCase() + '.' : val))
Vue.filter('capitalize', (val) => (val ? val.toUpperCase() : val))
Vue.filter('lowercase', (val) => (val ? val.toLowerCase() : val))

Vue.filter('humanDateTime', (val) => {
  if (!val) return '–'

  const date = new Date(String(val))
  if (isSameYear(date, new Date())) {
    return format(date, "dd MMM 'à' HH:mm", { locale: fr })
  }
  return format(date, "dd MMM yyyy 'à' HH:mm", { locale: fr })
})

Vue.filter('humanDate', (val) => {
  if (!val) return '–'

  const date = new Date(String(val))
  if (isSameYear(date, new Date())) {
    return format(date, 'dd MMM', { locale: fr })
  }
  return format(date, 'dd MMM yyyy', { locale: fr })
})

Vue.filter('humanDateLong', (val) => {
  if (!val) return '–'

  const date = new Date(String(val))
  if (isSameYear(date, new Date())) {
    return format(date, 'dd MMMM', { locale: fr })
  }
  return format(date, 'dd MMMM yyyy', { locale: fr })
})

Vue.filter('longDate', (val) => (val ? format(new Date(String(val)), 'dd/MM/yyyy', { locale: fr }) : '–'))

Vue.filter('closeDateMobile', (val) => {
  const now = new Date()
  const date = new Date(String(val))

  if (differenceInCalendarDays(now, date) > 0) {
    return format(date, 'dd/MM HH:mm')
  }

  return format(date, 'HH:mm')
})

Vue.filter('closeDate', (val) => {
  const now = new Date()
  const date = new Date(String(val))

  if (differenceInCalendarDays(now, date) > 0) {
    return format(date, "'Le' dd/MM 'à' HH:mm")
  }

  return format(date, "'à' HH:mm")
})

Vue.filter('number', (val, precision = 2) => {
  if (val === null) {
    val = 0
  }

  if (typeof val === 'number') {
    val = val.toFixed(precision)
  }

  return Number(val).toString().replace('.', ',').replace(',00', '')
})

Vue.filter('address', (val, hideCountry = true, joinFlat = false) => {
  const address = [
    _get(val, 'street', null),
    _get(val, 'complement', null),
    [_get(val, 'postal_code', null), _get(val, 'city', null)].filter((el) => el !== null).join(' '),
    !hideCountry ? _get(val, 'country', null) : null,
  ].filter((el) => el !== null && el !== 'FR' && el !== '')

  if (!address.filter((el) => el.toString().trim() !== '').length) {
    return null
  }
  return address.join(joinFlat ? ', ' : '<br />')
})

// lol
Vue.filter('documentAddress', (val, hideCountry = true) => {
  const address = [
    _get(val, 'street', null),
    _get(val, 'complement', null),
    [_get(val, 'postal_code', null), _get(val, 'city', null)].filter((el) => el !== null).join(' '),
    !hideCountry ? _get(val, 'country', null) : null,
  ].filter((el) => el !== null && el !== 'FR' && el !== '')

  if (address.filter((el) => el.toString().trim() !== '').length) {
    return address.join('<br />')
  }
})

/**
 * Format bytes as human-readable text.
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * @return Formatted string.
 */
Vue.filter('humanFileSize', (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
})
