//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api'
const colors = [
  '#f18482',
  '#fdd47b',
  '#f9ef83',
  '#e6e77e',
  '#aad29e',
  '#8acdd7',
  '#84c4ec',
  '#8f94ea',
  '#a776b4',
  '#df7db3',
  '#e94340',
  '#f5962f',
  '#faea30',
  '#d0da30',
  '#7bbc62',
  '#50bec7',
  '#4889c5',
  '#45519f',
]

export default {
  props: {
    mono: Boolean,
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'bg-gray-400 text-white',
    },
    autocolor: Boolean,
    xs: Boolean,
  },
  setup(props) {
    const initials = computed(() =>
      props.name
        ? props.name
            .split(' ', props.mono ? 1 : 2)
            .map((word) => word[0])
            .join('')
            .trim()
        : '?'
    )

    function hashCode(str) {
      let hash = 0
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      return hash
    }

    function pickColor(str) {
      const index = Math.round(Math.abs(hashCode(str) % 360) / 20)
      return colors[index]
    }

    return { props, initials, pickColor }
  },
}
