//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, set as _set } from 'lodash-es'
import { defineComponent, onMounted, ref, computed, watch, useContext } from '@nuxtjs/composition-api'

import {
  PRODUCT_TYPES,
  PRODUCT_TYPE_WORK,
  PRODUCT_TYPES_WORKS,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_WORKFORCE,
  BUILDER_PRODUCT_TYPES,
} from '@/constants/products'
import { useUser } from '@/composables/user'
import { formatUnit } from '@/utils/documents'
import { roundToTwo } from '@/utils/numbers'
import { stripHTML } from '@/utils/normalizers'
import { useLoading } from '@/composables/loading'

export default defineComponent({
  props: {
    deletable: Boolean,
    showReferences: Boolean,
    showMargins: Boolean,
    columnClasses: Object,
    context: String,
    item: {
      type: Object,
      default: () => {},
    },
    elements: Array,
    hasExistingElements: Boolean,
    isLast: Boolean,
    isFirst: Boolean,
    index: Number,
    dragging: Boolean,
    parent: Object,
  },
  setup(props, { emit }) {
    const { $productsRepository } = useContext()
    const { company, units, defaultUnit, defaultUnitObject } = useUser()
    const { setLoading } = useLoading()
    const productSearch = ref(null)

    // We can't include the same product twice in a work
    const excludeFromSearch = computed(() =>
      _get(props, 'elements', [])
        .map((el) => _get(el, 'element.id', null))
        .filter(Boolean)
    )

    const closeSearch = function () {
      if (productSearch.value !== null) {
        productSearch.value.toggleShowSearch(false)
      }
    }

    const marginRate = ref(null)

    const setMargin = ($event) => {
      marginRate.value = $event
      const buyPrice = _get(props.item, 'element.buyPrice', 0)
      if (buyPrice > 0) {
        const grossMargin = (buyPrice * $event) / 100
        props.item.element.sellPrice = roundToTwo(buyPrice + grossMargin)

        emit('update')
      }
    }

    const getMarginRate = () => {
      if (props.item.element && props.item.element.buyPrice && props.item.element.sellPrice) {
        const grossMargin = props.item.element.sellPrice - props.item.element.buyPrice
        marginRate.value = (grossMargin / props.item.element.buyPrice) * 100
      }
    }

    onMounted(() => getMarginRate())

    const setProductType = (type) => {
      if (type === PRODUCT_TYPE_WORKFORCE) {
        if (_get(props.item, `element.unit.symbol`) === _get(defaultUnitObject, `symbol`)) {
          let hourUnit = _get(company.value, 'units', []).find((el) => el.symbol === 'h')
          if (hourUnit) {
            props.item.element.unit = hourUnit
          }
        }
      }
      props.item.element.type = type
    }

    const setSellPrice = ($event) => {
      props.item.element.sellPrice = Math.round($event * 100 * 100) / 100
      if (props.item.element.buyPrice > 0) {
        const grossMargin = props.item.element.sellPrice - props.item.element.buyPrice
        marginRate.value = (grossMargin / props.item.element.buyPrice) * 100
      }
      emit('update')
    }

    const setBuyPrice = ($event) => {
      props.item.element.buyPrice = Math.round($event * 100 * 100) / 100

      if (props.item.element.buyPrice > 0) {
        let marginType = props.item.element.type

        if (marginType === PRODUCT_TYPE_WORK_DETAILED) {
          marginType = PRODUCT_TYPE_WORK
        }

        const applyMargin = _get(
          company.value,
          `defaultMargins.${marginType}`,
          _get(company.value, 'defaultMargin', 30)
        )
        const grossMargin = roundToTwo((props.item.element.buyPrice * applyMargin) / 100)

        if (!props.item.lockSellPrice) {
          props.item.element.sellPrice = roundToTwo(props.item.element.buyPrice + grossMargin)
          marginRate.value = (grossMargin / props.item.element.buyPrice) * 100
        } else {
          const grossMargin = props.item.element.sellPrice - props.item.element.buyPrice
          marginRate.value = (grossMargin / props.item.element.buyPrice) * 100
        }
      }

      emit('update')
    }

    const handleInput = ($event) => {
      if (productSearch.value !== null) {
        productSearch.value.searchProducts({ description: stripHTML($event), showSearch: true })
      }

      emit('input', $event)
    }

    const editor = ref(null)
    const selectResult = async function (result) {
      setLoading(true, `select_product_${result.uuid}`)
      try {
        const { data } = await $productsRepository.find(result.uuid, { _expand: ['images'] })
        let product = data

        let unit = null
        const unitId = _get(product, 'unit.id', null)
        if (unitId) unit = units.find((el) => el.id === unitId)
        if (!unit) unit = defaultUnitObject
        product.unit = unit

        if (editor.value && editor.value.updateValue) {
          editor.value.updateValue(product.name)
        }

        emit('select', product)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false, `select_product_${result.uuid}`)
      }
    }

    watch(
      () => props.item,
      (val) => getMarginRate()
    )

    watch(
      () => props.item.sellPrice,
      (val) => {
        console.log('update')
        getMarginRate()
      }
    )

    const handleResize = () => {
      if (productSearch.value) {
        productSearch.value.handleResize()
      }
    }

    const isEditable = computed(() => props.item.isNew || props.context === 'builder')

    return {
      _get,
      props,
      units,
      formatUnit,
      productSearch,
      excludeFromSearch,
      marginRate,
      isEditable,
      editor,
      handleResize,
      handleInput,
      selectResult,
      closeSearch,
      setSellPrice,
      setBuyPrice,
      setProductType,
      setMargin,
      PRODUCT_TYPES,
      PRODUCT_TYPE_WORK,
      PRODUCT_TYPES_WORKS,
      BUILDER_PRODUCT_TYPES,
    }
  },
})
