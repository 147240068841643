import { render, staticRenderFns } from "./HoldbackSidePanel.vue?vue&type=template&id=2ea10dcc&"
import script from "./HoldbackSidePanel.vue?vue&type=script&lang=js&"
export * from "./HoldbackSidePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Loading.vue').default,SidePanelCloseButton: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidePanelCloseButton.vue').default,HoldbackStatusBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/HoldbackStatusBadge.vue').default,ContactBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/contact/ContactBadge.vue').default,ProjectBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/project/ProjectBadge.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,SidePanel: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidePanel.vue').default})
