//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { useLoading } from '@/composables/loading'
import { commonCountries, countriesList } from '@/constants/countries'
import { PROJECT_COLORS } from '@/constants/projects'

import {
  computed,
  defineComponent,
  reactive,
  ref,
  useContext,
  useRouter,
  watch,
  watchEffect,
} from '@nuxtjs/composition-api'

import { createNamespacedHelpers } from 'vuex-composition-helpers'

import { get as _get, has as _has } from 'lodash-es'
import { nanoid } from 'nanoid'
import { getAddressString } from '@/utils/normalizers'
import { hasFormErrors } from '@/utils/error-handler'
import { CONTACT_TYPE_CLIENT, CONTACT_TYPE_LEAD } from '@/constants/contacts'
import { isBefore } from 'date-fns'
import { projectColors } from '@/utils/palette'
import { useUser } from '@/composables/user'

const { useGetters } = createNamespacedHelpers('documents')

const defaultForm = () => ({
  name: '',
  color: '#8f94ea',
  startAt: null,
  endAt: null,
  clientAddress: false,
  address: {
    street: '',
    complement: '',
    postal_code: '',
    city: '',
    country: 'fr',
  },
})

const defaultErrors = () => ({
  customer: false,
  category: false,
  address: false,
  startAt: false,
  endAt: false,
})

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    forCustomer: {
      type: Object,
      default: () => {},
    },
    forCategory: {
      type: Object,
      default: null,
    },
    open: Boolean,
  },
  setup(props, { emit }) {
    const modal = useModal()
    const router = useRouter()
    const mapsId = ref(nanoid())
    const addressSearch = ref(null)
    const { isLoading, setLoading } = useLoading()
    const { $toast, $bus, $projectsRepository } = useContext()
    const { subscriptionUsers } = useUser()

    const project = ref(null)
    const init = ref(false)

    const form = reactive(defaultForm())
    const errors = reactive(defaultErrors())

    const showFullAddress = ref(false)
    const addressField = ref(null)
    const projectId = ref(null)

    const customer = ref(null)
    const category = ref(null)
    const assignee = ref(null)

    const initForm = (data) => {
      for (const key of Object.keys(defaultForm())) {
        if (_has(data, key)) {
          let val = data[key]
          if (['total', 'subtotal'].includes(key) && val !== null) {
            val /= 100
          }
          form[key] = val
        }
      }

      if (!form.color) {
        form.color = '#8f94ea'
      }

      init.value = true
    }

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        init.value = false
        if (val === true) {
          mapsId.value = nanoid()
          if (_has(props.forCustomer, 'id')) {
            customer.value = props.forCustomer
            form.clientAddress = true
          }
          if (_has(props.forCategory, 'id')) {
            category.value = props.forCategory
          }

          if (props.mode === 'update') {
            // ID is passed
            if (props.id) {
              projectId.value = props.id
              try {
                setLoading(true, `fetch_${props.id}`)
                let { data } = await $projectsRepository.find(props.id, { _expand: 'assignee' })

                category.value = data.category
                assignee.value = data.assignee
                customer.value = data.customer

                if (data.address === null) {
                  data.address = {
                    street: '',
                    complement: '',
                    postal_code: '',
                    city: '',
                    country: '',
                  }
                }

                if (_get(form, 'address.street', null)) {
                  addressSearch.value = getAddressString(_get(form, 'address', {}))
                }

                project.value = data
                initForm(data)
              } catch (err) {
                console.log(err)
              } finally {
                setLoading(false, `fetch_${props.id}`)
              }
            }
          } else {
            init.value = true
          }

          if (props.name) {
            form.name = props.name
          }
        }
      }
    )

    const createProjectContactModal = ref(null)
    const searchContact = ref(null)

    const getFullName = (user) =>
      user
        ? [user.firstName || _get(user, 'user.firstName'), user.lastName || _get(user, 'user.lastName')]
            .filter(Boolean)
            .join(' ')
            .trim()
        : ''

    const selectAssignee = ($event) => {
      assignee.value = $event ? $event.user : null
    }

    const selectCustomer = ($event) => {
      customer.value = $event
      errors.customer = false
    }

    function handleItemCreated(item) {
      if (searchContact.value) {
        searchContact.value.injectItem(item)
      }
      customer.value = item
    }

    const handlePlanningDeleted = () => {
      project.value.tasksCount = 0
      form.startAt = null
      form.endAt = null
    }

    const selectCountries = computed(() => [
      ...Object.keys(commonCountries).reduce(
        (arr, key) => [
          ...arr,
          ...[
            {
              value: key,
              label: commonCountries[key],
            },
          ],
        ],
        []
      ),
      { value: null, label: '------', disabled: true },
      ...Object.keys(countriesList).reduce((arr, key) => {
        if (!Object.keys(commonCountries).includes(key)) {
          arr.push({ value: key, label: countriesList[key] })
        }
        return arr
      }, []),
    ])

    async function submitForm(instance) {
      if (!hasFormErrors(errors)) {
        setLoading(true, 'create_project')

        try {
          const projectData = { ...form }

          // Sets address to null if fields are empty, TODO check what we do with different countries
          if (
            projectData.customer !== null &&
            Object.entries(projectData.address).filter(
              ([k, v]) => k !== 'country' && typeof v === 'string' && v.trim() !== ''
            ).length === 0
          ) {
            projectData.address = null
          }

          if (projectData.customer !== null && projectData.clientAddress === true) {
            projectData.address = null
          }

          let additional = {
            category: category.value.id,
            customer: _get(customer.value, 'id', null),
            assignee: _get(assignee.value, 'id', null),
          }

          const { data } = projectId.value
            ? await $projectsRepository.update(
                projectId.value,
                {
                  ...projectData,
                  ...additional,
                },
                { _expand: ['customer', 'assignee'] }
              )
            : await $projectsRepository.create({ ...projectData, ...additional }, { _expand: ['customer', 'assignee'] })

          $toast.show(props.mode === 'create' ? 'Le projet a bien été créé' : 'Le projet a bien été mis à jour')
          $bus.emit(props.mode === 'create' ? 'create.item' : 'update.item', { type: 'project', data })
          emit(props.mode === 'create' ? 'created' : 'updated', data)
          if (props.open) {
            await router.push(`/projects/${data.id}`)
          }
          triggerCloseModal(false)
        } catch (err) {
          console.log(err)
        } finally {
          setLoading(false, 'create_project', 2000)
        }
      }
    }

    function triggerCloseModal(abort = true) {
      if (abort) {
        emit('aborted')
      }

      customer.value = null
      category.value = null
      Object.assign(form, defaultForm())
      Object.assign(errors, defaultErrors())

      modal.closeModal()
    }

    const toggleManualAddress = () => {
      showFullAddress.value = !showFullAddress.value
      if (showFullAddress.value === true && addressField.value) {
        addressField.value.clear()
      }
    }

    watch(customer, (val) => {
      if (val === null && form.clientAddress) {
        form.clientAddress = false
      }
    })

    watchEffect(() => {
      if (modal.isModalOpen() && form.startAt && form.endAt && isBefore(new Date(form.endAt), new Date(form.startAt))) {
        form.endAt = new Date(form.startAt)
      }
    })

    const deleteModal = ref(null)

    return {
      init,
      PROJECT_COLORS,
      CONTACT_TYPE_CLIENT,
      CONTACT_TYPE_LEAD,
      ...modal,
      triggerCloseModal,
      subscriptionUsers,
      createProjectContactModal,
      searchContact,
      mapsId,
      form,
      project,
      errors,
      isLoading,
      customer,
      category,
      assignee,
      selectCountries,
      showFullAddress,
      addressField,
      toggleManualAddress,
      addressSearch,
      getFullName,
      selectAssignee,
      selectCustomer,
      submitForm,
      handleItemCreated,
      handlePlanningDeleted,
      deleteModal,
      projectColors,
      _get,
    }
  },
})
