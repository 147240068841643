import { ref } from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
import { toValue } from '@vueuse/core'

export function useInfiniteScroll(key, fetch) {
  const { isLoading } = useLoading()
  const scrollContainer = ref(null)
  const page = ref(1)
  const endOfList = ref(false)

  const handleScroll = async (event) => {
    let { scrollTop, scrollHeight, clientHeight } = event.target

    if (!isLoading(key) && scrollHeight - clientHeight - 100 < scrollTop && !endOfList.value) {
      page.value += 1
      await fetch()
    }
  }

  const handleMetadata = (data, metadata) => {
    if (data.length >= metadata.items) {
      endOfList.value = true
      if (scrollContainer.value) {
        scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
      }
    }
  }

  const handleEventListener = (add) => {
    endOfList.value = false
    if (scrollContainer.value) {
      if (add) {
        scrollContainer.value.scrollElement.addEventListener('scroll', handleScroll)
      } else {
        scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
      }
    }
  }

  return { fetch, page, scrollContainer, endOfList, handleMetadata, handleScroll, handleEventListener }
}

export function useSharedInfiniteScroll(scrollContainer, key, active, fetch) {
  const { isLoading } = useLoading()
  const page = ref(1)
  const endOfList = ref(false)

  const handleScroll = async (event) => {
    const isActive = toValue(active)
    if (isActive) {
      let { scrollTop, scrollHeight, clientHeight } = event.target
      if (!isLoading(key) && scrollHeight - clientHeight - 100 < scrollTop && !endOfList.value) {
        page.value += 1
        await fetch()
      }
    }
  }

  const handleMetadata = (data, metadata) => {
    if (data.length >= metadata.items) {
      endOfList.value = true
      if (scrollContainer.value) {
        scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
      }
    }
  }

  const handleEventListener = (add) => {
    if (scrollContainer.value) {
      if (add) {
        scrollContainer.value.scrollElement.addEventListener('scroll', handleScroll)
      } else {
        scrollContainer.value.scrollElement.removeEventListener('scroll', handleScroll)
      }
    }
  }

  return { fetch, page, scrollContainer, endOfList, handleMetadata, handleScroll, handleEventListener }
}
