import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useMutations, useState } = createNamespacedHelpers('loading')

export function useLoading() {
  const { loaders } = useState(['loaders'])
  const { setLoader } = useMutations(['setLoader'])

  function isLoading(key) {
    return loaders.value.findIndex((el) => el === key) !== -1
  }

  function setLoading(val = true, key = null, timeout = null) {
    setLoader({ key, val })
  }

  return { setLoading, isLoading, loaders }
}

export function useErrorState() {
  const { errors } = useState(['errors'])
  const { setError } = useMutations(['setError'])

  function hasErrorState(key) {
    return errors.value.findIndex((el) => el === key) !== -1
  }

  function setErrorState(key = null) {
    setError({ key, val: true })
    setTimeout(() => {
      setError({ key, val: false })
    }, 3000)
  }

  return { setErrorState, hasErrorState }
}

/*export function useErrorState() {
  const errorState = ref(false)
  const errorStates = ref([])

  function hasErrorState(key) {
    return errorStates.value.findIndex((el) => el === key) !== -1
  }

  function setErrorState(key = null) {
    if (key === null) {
      errorState.value = true
    } else {
      errorStates.value.push(key)
    }

    setTimeout(() => {
      if (key === null) {
        errorState.value = false
      } else {
        const index = errorStates.value.findIndex((el) => el === key)
        errorStates.value.splice(index, 1)
      }
    }, 3000)
  }

  return { errorState, errorStates, setErrorState, hasErrorState }
}*/
