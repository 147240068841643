import { camelCase, snakeCase } from 'scule'
import resources from '~/api/resources'

import { get as _get, has as _has } from 'lodash-es'
import { withoutTrailingSlash, withQuery } from 'ufo'
import { pluralizeType } from '@/api/resources'
import { ref } from '@nuxtjs/composition-api'
import { nanoid } from 'nanoid/non-secure'

export default (ctx, inject) => {
  const controllers = ref({})
  const createRepository = ($axios) => (resource) => {
    resource = snakeCase(resource)

    return {
      list(params) {
        /*
        let loadingKey = nanoid()
        try {
          loadingKey = params.loadingKey || JSON.stringify(params)
        } catch (err) {}

        if (controllers.value[loadingKey]) {
          controllers.value[loadingKey].cancel('dedupe')
          controllers.value[loadingKey] = undefined
        }

        const CancelToken = $axios.CancelToken
        controllers.value[loadingKey] = CancelToken.source()
        const token = controllers.value[loadingKey].token

        return $axios.$get(withoutTrailingSlash(`api/${resource}`), { ...params, cancelToken: token })
      */

        return $axios.$get(withoutTrailingSlash(`api/${resource}`), params)
      },
      create(payload, query) {
        return $axios.$post(withoutTrailingSlash(`api/${resource}`), payload, { params: query, progress: false })
      },
      find(id, params) {
        return $axios.$get(withoutTrailingSlash(`api/${resource}/${id}`), {
          params: params,
          progress: false,
        })
      },
      update(id, payload, query) {
        return $axios.$put(withoutTrailingSlash(`api/${resource}/${id}`), payload, { params: query, progress: false })
      },
      get(id, path, params) {
        return $axios.$get(withoutTrailingSlash(`api/${resource}/${id}/${path !== undefined ? path : ''}`), {
          params,
          progress: false,
        })
      },
      getUrl(id, path, params) {
        return withQuery(process.env.API_URL + `/api/${resource}/${id}/${path}`, params)
      },
      post(id, path, params, query) {
        return $axios.$post(withoutTrailingSlash(`api/${resource}/${id}/${path}`), params, {
          params: query,
          progress: false,
        })
      },
      put(id, path, params, query) {
        return $axios.$put(withoutTrailingSlash(`api/${resource}/${id}/${path}`), params, {
          params: query,
          progress: false,
        })
      },
      delete(id, path = '') {
        if (path !== '') {
          path = '/' + path
        }
        return $axios.$delete(withoutTrailingSlash(`api/${resource}/${id}${path}`), { progress: false })
      },
    }
  }

  const getRepository =
    ({ app, error }) =>
    (repository) => {
      if (_has(repository, 'object')) {
        repository = pluralizeType[repository.object]
      }

      repository = camelCase(repository)

      const repo = _get(app, `$${repository}Repository`, null)
      if (!repo) {
        console.log('Repository loading error : ')
        console.log(repository)
      }

      if (!repo) {
        error({
          statusCode: 500,
          message: 'No repository found for "' + repository + '". See @/plugins/repository for repository injections',
        })
      }

      return repo
    }

  const repository = createRepository(ctx.app.$axios)

  inject('getRepository', getRepository(ctx))
  resources.forEach((resource) => inject(`${camelCase(resource)}Repository`, repository(resource)))
}
