//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, useContext, computed, useRouter } from '@nuxtjs/composition-api'
import { useDocumentActions } from '@/composables/document'
import { useLoading } from '@/composables/loading'
import {
  QUOTE,
  INVOICE,
  CREDIT,
  ORDER_FORM,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENT_STATUS_OPEN,
  DOCUMENT_STATUS_DELIVERED,
  DOCUMENT_STATUS_SHIPPED,
} from '@/constants/documents'

import { differenceInCalendarDays } from 'date-fns'
import { useClipboard } from '@vueuse/core/index'
import { get as _get } from 'lodash-es'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    contained: Boolean,
  },
  setup(props, { emit }) {
    const { app, $bus, $toast, $getRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { downloadPDF } = useDocumentActions($getRepository(props.document))

    const pluralType = computed(() => pluralizeType[props.document.objectType])

    const action = async (action) => {
      switch (action) {
        case 'send':
          $bus.emit('toggleSend', props.document)
          break
        case 'share':
          $bus.emit('toggleShare', props.document)
          break
        case 'download':
          let downloaded = await downloadPDF(props.document.id, 'download')
          if (downloaded && !props.document.sent) {
            $bus.emit('toggleMarkAsSent', props.document)
          }
          break
        case 'print':
          let printed = await downloadPDF(props.document.id, 'print')
          if (printed && !props.document.sent) {
            $bus.emit('toggleMarkAsSent', props.document)
          }
          break
      }
    }

    const isPastDue = computed(
      () =>
        props.document.paidAt === null &&
        [DOCUMENT_STATUS_OPEN].includes(props.document.status) &&
        differenceInCalendarDays(new Date(), new Date(props.document.expireAt)) > 0
    )

    const sendButtonColor = computed(() => {
      switch (props.document.objectType) {
        case QUOTE:
          return !props.document.invoiced && (props.document.acceptedAt || props.document.sentAt) ? 'gray' : 'brand'
        case INVOICE:
          return props.document.sentAt
            ? isPastDue.value
              ? 'red'
              : 'gray'
            : props.document.acceptedAt || props.document.sentAt
            ? 'gray'
            : 'brand'
        case CREDIT:
        case ORDER_FORM:
          return props.document.sent ||
            [DOCUMENT_STATUS_DELIVERED, DOCUMENT_STATUS_SHIPPED].includes(props.document.status)
            ? 'gray'
            : 'brand'
      }
    })

    const shareLink = ref(null)
    const { copy, copied, isSupported } = useClipboard({ source: shareLink })

    const toggleShare = async (hide) => {
      try {
        setLoading(true, `document_${props.document.id}_share`)
        const { data } = await $getRepository(props.document).get(props.document.id, 'share')
        shareLink.value = _get(data, 'public_url', '')
        await copy(shareLink.value)
        //$toast.show('Le lien a été copié dans le presse-papiers.')
        //hide()
      } catch (err) {
      } finally {
        setLoading(false, `document_${props.document.id}_share`)
      }
    }

    async function markAsSent() {
      try {
        setLoading(true, `mark_as_sent_${props.document.id}`)
        const openDocument = await $getRepository(props.document).post(props.document.id, 'mark_sent')

        $bus.emit('sent.item', props.document.id)
        $bus.emit('update.item', {
          type: props.document.object,
          data: { ...props.document, ...{ sent: true, sentAt: new Date() } },
        })
        $toast.show(app.i18n.t('documents.notice.markedAsSent'))
      } catch (err) {
      } finally {
        setLoading(false, `mark_as_sent_${props.document.id}`)
      }
    }

    return {
      INVOICE,
      CREDIT,
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_CANCELLED,
      props,
      isPastDue,
      pluralType,
      open,
      isLoading,
      sendButtonColor,
      action,
      toggleShare,
      copied,
      isSupported,
      markAsSent,
    }
  },
})
